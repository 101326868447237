/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getData = /* GraphQL */ `
  query GetData($id: ID!) {
    getData(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listData = /* GraphQL */ `
  query ListData(
    $filter: ModelDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroceryFund = /* GraphQL */ `
  query GetGroceryFund($id: ID!) {
    getGroceryFund(id: $id) {
      id
      GroceriesFundName
      GroceriesFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const listGroceryFunds = /* GraphQL */ `
  query ListGroceryFunds(
    $filter: ModelGroceryFundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroceryFunds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        GroceriesFundName
        GroceriesFundAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBriGroceryExpense = /* GraphQL */ `
  query GetBriGroceryExpense($id: ID!) {
    getBriGroceryExpense(id: $id) {
      id
      BriGroceryExpenseName
      BriGroceriesExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listBriGroceryExpenses = /* GraphQL */ `
  query ListBriGroceryExpenses(
    $filter: ModelBriGroceryExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBriGroceryExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        BriGroceryExpenseName
        BriGroceriesExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJustinGroceryExpense = /* GraphQL */ `
  query GetJustinGroceryExpense($id: ID!) {
    getJustinGroceryExpense(id: $id) {
      id
      JustinGroceriesExpenseName
      JustinGroceriesExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listJustinGroceryExpenses = /* GraphQL */ `
  query ListJustinGroceryExpenses(
    $filter: ModelJustinGroceryExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJustinGroceryExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JustinGroceriesExpenseName
        JustinGroceriesExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEntertainmentFund = /* GraphQL */ `
  query GetEntertainmentFund($id: ID!) {
    getEntertainmentFund(id: $id) {
      id
      EntertainmentFundName
      EntertainmentFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const listEntertainmentFunds = /* GraphQL */ `
  query ListEntertainmentFunds(
    $filter: ModelEntertainmentFundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntertainmentFunds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        EntertainmentFundName
        EntertainmentFundAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBriEntertainmentExpense = /* GraphQL */ `
  query GetBriEntertainmentExpense($id: ID!) {
    getBriEntertainmentExpense(id: $id) {
      id
      BriEntertainmentExpenseName
      BriEntertainmentExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listBriEntertainmentExpenses = /* GraphQL */ `
  query ListBriEntertainmentExpenses(
    $filter: ModelBriEntertainmentExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBriEntertainmentExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        BriEntertainmentExpenseName
        BriEntertainmentExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJustinEntertainmentExpense = /* GraphQL */ `
  query GetJustinEntertainmentExpense($id: ID!) {
    getJustinEntertainmentExpense(id: $id) {
      id
      JustinEntertainmentExpenseName
      JustinEntertainmentExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listJustinEntertainmentExpenses = /* GraphQL */ `
  query ListJustinEntertainmentExpenses(
    $filter: ModelJustinEntertainmentExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJustinEntertainmentExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JustinEntertainmentExpenseName
        JustinEntertainmentExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGasFund = /* GraphQL */ `
  query GetGasFund($id: ID!) {
    getGasFund(id: $id) {
      id
      GasFundName
      GasFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const listGasFunds = /* GraphQL */ `
  query ListGasFunds(
    $filter: ModelGasFundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGasFunds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        GasFundName
        GasFundAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBriGasExpense = /* GraphQL */ `
  query GetBriGasExpense($id: ID!) {
    getBriGasExpense(id: $id) {
      id
      BriGasExpenseName
      BriGasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listBriGasExpenses = /* GraphQL */ `
  query ListBriGasExpenses(
    $filter: ModelBriGasExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBriGasExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        BriGasExpenseName
        BriGasExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJustinGasExpense = /* GraphQL */ `
  query GetJustinGasExpense($id: ID!) {
    getJustinGasExpense(id: $id) {
      id
      JustinGasExpenseName
      JustinGasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listJustinGasExpenses = /* GraphQL */ `
  query ListJustinGasExpenses(
    $filter: ModelJustinGasExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJustinGasExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JustinGasExpenseName
        JustinGasExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJustinExpense = /* GraphQL */ `
  query GetJustinExpense($id: ID!) {
    getJustinExpense(id: $id) {
      id
      JustinExpenseName
      JustinExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listJustinExpenses = /* GraphQL */ `
  query ListJustinExpenses(
    $filter: ModelJustinExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJustinExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        JustinExpenseName
        JustinExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBriExpense = /* GraphQL */ `
  query GetBriExpense($id: ID!) {
    getBriExpense(id: $id) {
      id
      BriExpenseName
      BriExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listBriExpenses = /* GraphQL */ `
  query ListBriExpenses(
    $filter: ModelBriExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBriExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        BriExpenseName
        BriExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIanFund = /* GraphQL */ `
  query GetIanFund($id: ID!) {
    getIanFund(id: $id) {
      id
      IanFundName
      IanFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const listIanFunds = /* GraphQL */ `
  query ListIanFunds(
    $filter: ModelIanFundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIanFunds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        IanFundName
        IanFundAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJustinIanExpense = /* GraphQL */ `
  query GetJustinIanExpense($id: ID!) {
    getJustinIanExpense(id: $id) {
      id
      JustinIanName
      JustinIanExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listJustinIanExpenses = /* GraphQL */ `
  query ListJustinIanExpenses(
    $filter: ModelJustinIanExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJustinIanExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JustinIanName
        JustinIanExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBriIanExpense = /* GraphQL */ `
  query GetBriIanExpense($id: ID!) {
    getBriIanExpense(id: $id) {
      id
      BriIanExpenseName
      BriIanExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listBriIanExpenses = /* GraphQL */ `
  query ListBriIanExpenses(
    $filter: ModelBriIanExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBriIanExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        BriIanExpenseName
        BriIanExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBriExtrasExpense = /* GraphQL */ `
  query GetBriExtrasExpense($id: ID!) {
    getBriExtrasExpense(id: $id) {
      id
      BriExtrasExpenseName
      BriExtrasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listBriExtrasExpenses = /* GraphQL */ `
  query ListBriExtrasExpenses(
    $filter: ModelBriExtrasExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBriExtrasExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        BriExtrasExpenseName
        BriExtrasExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJustinExtrasExpense = /* GraphQL */ `
  query GetJustinExtrasExpense($id: ID!) {
    getJustinExtrasExpense(id: $id) {
      id
      JustinExtrasName
      JustinExtrasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listJustinExtrasExpenses = /* GraphQL */ `
  query ListJustinExtrasExpenses(
    $filter: ModelJustinExtrasExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJustinExtrasExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JustinExtrasName
        JustinExtrasExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExtrasFund = /* GraphQL */ `
  query GetExtrasFund($id: ID!) {
    getExtrasFund(id: $id) {
      id
      ExtrasFundName
      ExtrasFundExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listExtrasFunds = /* GraphQL */ `
  query ListExtrasFunds(
    $filter: ModelExtrasFundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExtrasFunds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ExtrasFundName
        ExtrasFundExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmergencySavingsAmount = /* GraphQL */ `
  query GetEmergencySavingsAmount($id: ID!) {
    getEmergencySavingsAmount(id: $id) {
      id
      EmergencySavingsName
      totalEmergencySavings
      createdAt
      updatedAt
    }
  }
`;
export const listEmergencySavingsAmounts = /* GraphQL */ `
  query ListEmergencySavingsAmounts(
    $filter: ModelEmergencySavingsAmountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmergencySavingsAmounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        EmergencySavingsName
        totalEmergencySavings
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBriEmergencyExpense = /* GraphQL */ `
  query GetBriEmergencyExpense($id: ID!) {
    getBriEmergencyExpense(id: $id) {
      id
      BriEmergencyExpenseName
      BriEmergencyExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listBriEmergencyExpenses = /* GraphQL */ `
  query ListBriEmergencyExpenses(
    $filter: ModelBriEmergencyExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBriEmergencyExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        BriEmergencyExpenseName
        BriEmergencyExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJustinEmergencyExpense = /* GraphQL */ `
  query GetJustinEmergencyExpense($id: ID!) {
    getJustinEmergencyExpense(id: $id) {
      id
      JustinEmergencyExpenseName
      JustinEmergencyExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listJustinEmergencyExpenses = /* GraphQL */ `
  query ListJustinEmergencyExpenses(
    $filter: ModelJustinEmergencyExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJustinEmergencyExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JustinEmergencyExpenseName
        JustinEmergencyExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBill = /* GraphQL */ `
  query GetBill($id: ID!) {
    getBill(id: $id) {
      id
      BillName
      BillAmount
      createdAt
      updatedAt
    }
  }
`;
export const listBills = /* GraphQL */ `
  query ListBills(
    $filter: ModelBillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        BillName
        BillAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTravelAmount = /* GraphQL */ `
  query GetTravelAmount($id: ID!) {
    getTravelAmount(id: $id) {
      id
      TravelName
      TravelAmount
      createdAt
      updatedAt
    }
  }
`;
export const listTravelAmounts = /* GraphQL */ `
  query ListTravelAmounts(
    $filter: ModelTravelAmountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTravelAmounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        TravelName
        TravelAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBriTravelExpense = /* GraphQL */ `
  query GetBriTravelExpense($id: ID!) {
    getBriTravelExpense(id: $id) {
      id
      BriTravelExpenseName
      BriTravelExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listBriTravelExpenses = /* GraphQL */ `
  query ListBriTravelExpenses(
    $filter: ModelBriTravelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBriTravelExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        BriTravelExpenseName
        BriTravelExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJustinTravelExpense = /* GraphQL */ `
  query GetJustinTravelExpense($id: ID!) {
    getJustinTravelExpense(id: $id) {
      id
      JustinTravelExpenseName
      JustinTravelExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const listJustinTravelExpenses = /* GraphQL */ `
  query ListJustinTravelExpenses(
    $filter: ModelJustinTravelExpenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJustinTravelExpenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        JustinTravelExpenseName
        JustinTravelExpenseAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
