import logo from "../logo.svg";
import BriExpenseDialog from "../components/BriExpenses/BriExpenseDialog";
import BriPieDialog from "../components/BriPie/BriPieDialog";

const Bri = () => {
    return (
        <div className="App">
            <BriPieDialog/>
            <BriExpenseDialog/>
        </div>
    );
}
export default Bri;