import JustinExpenseDialog from "../components/JustinExpenses/JustinExpenseDialog";
import JustinPieDialog from "../components/JustinPie/JustinPieDialog";

const Justin = () => {
    return (
        <div className="App">
            <JustinPieDialog/>
            <JustinExpenseDialog/>
        </div>
    );
}
export default Justin;