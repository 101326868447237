/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createData = /* GraphQL */ `
  mutation CreateData(
    $input: CreateDataInput!
    $condition: ModelDataConditionInput
  ) {
    createData(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateData = /* GraphQL */ `
  mutation UpdateData(
    $input: UpdateDataInput!
    $condition: ModelDataConditionInput
  ) {
    updateData(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteData = /* GraphQL */ `
  mutation DeleteData(
    $input: DeleteDataInput!
    $condition: ModelDataConditionInput
  ) {
    deleteData(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createGroceryFund = /* GraphQL */ `
  mutation CreateGroceryFund(
    $input: CreateGroceryFundInput!
    $condition: ModelGroceryFundConditionInput
  ) {
    createGroceryFund(input: $input, condition: $condition) {
      id
      GroceriesFundName
      GroceriesFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateGroceryFund = /* GraphQL */ `
  mutation UpdateGroceryFund(
    $input: UpdateGroceryFundInput!
    $condition: ModelGroceryFundConditionInput
  ) {
    updateGroceryFund(input: $input, condition: $condition) {
      id
      GroceriesFundName
      GroceriesFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroceryFund = /* GraphQL */ `
  mutation DeleteGroceryFund(
    $input: DeleteGroceryFundInput!
    $condition: ModelGroceryFundConditionInput
  ) {
    deleteGroceryFund(input: $input, condition: $condition) {
      id
      GroceriesFundName
      GroceriesFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const createBriGroceryExpense = /* GraphQL */ `
  mutation CreateBriGroceryExpense(
    $input: CreateBriGroceryExpenseInput!
    $condition: ModelBriGroceryExpenseConditionInput
  ) {
    createBriGroceryExpense(input: $input, condition: $condition) {
      id
      BriGroceryExpenseName
      BriGroceriesExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateBriGroceryExpense = /* GraphQL */ `
  mutation UpdateBriGroceryExpense(
    $input: UpdateBriGroceryExpenseInput!
    $condition: ModelBriGroceryExpenseConditionInput
  ) {
    updateBriGroceryExpense(input: $input, condition: $condition) {
      id
      BriGroceryExpenseName
      BriGroceriesExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteBriGroceryExpense = /* GraphQL */ `
  mutation DeleteBriGroceryExpense(
    $input: DeleteBriGroceryExpenseInput!
    $condition: ModelBriGroceryExpenseConditionInput
  ) {
    deleteBriGroceryExpense(input: $input, condition: $condition) {
      id
      BriGroceryExpenseName
      BriGroceriesExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createJustinGroceryExpense = /* GraphQL */ `
  mutation CreateJustinGroceryExpense(
    $input: CreateJustinGroceryExpenseInput!
    $condition: ModelJustinGroceryExpenseConditionInput
  ) {
    createJustinGroceryExpense(input: $input, condition: $condition) {
      id
      JustinGroceriesExpenseName
      JustinGroceriesExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateJustinGroceryExpense = /* GraphQL */ `
  mutation UpdateJustinGroceryExpense(
    $input: UpdateJustinGroceryExpenseInput!
    $condition: ModelJustinGroceryExpenseConditionInput
  ) {
    updateJustinGroceryExpense(input: $input, condition: $condition) {
      id
      JustinGroceriesExpenseName
      JustinGroceriesExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteJustinGroceryExpense = /* GraphQL */ `
  mutation DeleteJustinGroceryExpense(
    $input: DeleteJustinGroceryExpenseInput!
    $condition: ModelJustinGroceryExpenseConditionInput
  ) {
    deleteJustinGroceryExpense(input: $input, condition: $condition) {
      id
      JustinGroceriesExpenseName
      JustinGroceriesExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createEntertainmentFund = /* GraphQL */ `
  mutation CreateEntertainmentFund(
    $input: CreateEntertainmentFundInput!
    $condition: ModelEntertainmentFundConditionInput
  ) {
    createEntertainmentFund(input: $input, condition: $condition) {
      id
      EntertainmentFundName
      EntertainmentFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateEntertainmentFund = /* GraphQL */ `
  mutation UpdateEntertainmentFund(
    $input: UpdateEntertainmentFundInput!
    $condition: ModelEntertainmentFundConditionInput
  ) {
    updateEntertainmentFund(input: $input, condition: $condition) {
      id
      EntertainmentFundName
      EntertainmentFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteEntertainmentFund = /* GraphQL */ `
  mutation DeleteEntertainmentFund(
    $input: DeleteEntertainmentFundInput!
    $condition: ModelEntertainmentFundConditionInput
  ) {
    deleteEntertainmentFund(input: $input, condition: $condition) {
      id
      EntertainmentFundName
      EntertainmentFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const createBriEntertainmentExpense = /* GraphQL */ `
  mutation CreateBriEntertainmentExpense(
    $input: CreateBriEntertainmentExpenseInput!
    $condition: ModelBriEntertainmentExpenseConditionInput
  ) {
    createBriEntertainmentExpense(input: $input, condition: $condition) {
      id
      BriEntertainmentExpenseName
      BriEntertainmentExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateBriEntertainmentExpense = /* GraphQL */ `
  mutation UpdateBriEntertainmentExpense(
    $input: UpdateBriEntertainmentExpenseInput!
    $condition: ModelBriEntertainmentExpenseConditionInput
  ) {
    updateBriEntertainmentExpense(input: $input, condition: $condition) {
      id
      BriEntertainmentExpenseName
      BriEntertainmentExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteBriEntertainmentExpense = /* GraphQL */ `
  mutation DeleteBriEntertainmentExpense(
    $input: DeleteBriEntertainmentExpenseInput!
    $condition: ModelBriEntertainmentExpenseConditionInput
  ) {
    deleteBriEntertainmentExpense(input: $input, condition: $condition) {
      id
      BriEntertainmentExpenseName
      BriEntertainmentExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createJustinEntertainmentExpense = /* GraphQL */ `
  mutation CreateJustinEntertainmentExpense(
    $input: CreateJustinEntertainmentExpenseInput!
    $condition: ModelJustinEntertainmentExpenseConditionInput
  ) {
    createJustinEntertainmentExpense(input: $input, condition: $condition) {
      id
      JustinEntertainmentExpenseName
      JustinEntertainmentExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateJustinEntertainmentExpense = /* GraphQL */ `
  mutation UpdateJustinEntertainmentExpense(
    $input: UpdateJustinEntertainmentExpenseInput!
    $condition: ModelJustinEntertainmentExpenseConditionInput
  ) {
    updateJustinEntertainmentExpense(input: $input, condition: $condition) {
      id
      JustinEntertainmentExpenseName
      JustinEntertainmentExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteJustinEntertainmentExpense = /* GraphQL */ `
  mutation DeleteJustinEntertainmentExpense(
    $input: DeleteJustinEntertainmentExpenseInput!
    $condition: ModelJustinEntertainmentExpenseConditionInput
  ) {
    deleteJustinEntertainmentExpense(input: $input, condition: $condition) {
      id
      JustinEntertainmentExpenseName
      JustinEntertainmentExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createGasFund = /* GraphQL */ `
  mutation CreateGasFund(
    $input: CreateGasFundInput!
    $condition: ModelGasFundConditionInput
  ) {
    createGasFund(input: $input, condition: $condition) {
      id
      GasFundName
      GasFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateGasFund = /* GraphQL */ `
  mutation UpdateGasFund(
    $input: UpdateGasFundInput!
    $condition: ModelGasFundConditionInput
  ) {
    updateGasFund(input: $input, condition: $condition) {
      id
      GasFundName
      GasFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteGasFund = /* GraphQL */ `
  mutation DeleteGasFund(
    $input: DeleteGasFundInput!
    $condition: ModelGasFundConditionInput
  ) {
    deleteGasFund(input: $input, condition: $condition) {
      id
      GasFundName
      GasFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const createBriGasExpense = /* GraphQL */ `
  mutation CreateBriGasExpense(
    $input: CreateBriGasExpenseInput!
    $condition: ModelBriGasExpenseConditionInput
  ) {
    createBriGasExpense(input: $input, condition: $condition) {
      id
      BriGasExpenseName
      BriGasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateBriGasExpense = /* GraphQL */ `
  mutation UpdateBriGasExpense(
    $input: UpdateBriGasExpenseInput!
    $condition: ModelBriGasExpenseConditionInput
  ) {
    updateBriGasExpense(input: $input, condition: $condition) {
      id
      BriGasExpenseName
      BriGasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteBriGasExpense = /* GraphQL */ `
  mutation DeleteBriGasExpense(
    $input: DeleteBriGasExpenseInput!
    $condition: ModelBriGasExpenseConditionInput
  ) {
    deleteBriGasExpense(input: $input, condition: $condition) {
      id
      BriGasExpenseName
      BriGasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createJustinGasExpense = /* GraphQL */ `
  mutation CreateJustinGasExpense(
    $input: CreateJustinGasExpenseInput!
    $condition: ModelJustinGasExpenseConditionInput
  ) {
    createJustinGasExpense(input: $input, condition: $condition) {
      id
      JustinGasExpenseName
      JustinGasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateJustinGasExpense = /* GraphQL */ `
  mutation UpdateJustinGasExpense(
    $input: UpdateJustinGasExpenseInput!
    $condition: ModelJustinGasExpenseConditionInput
  ) {
    updateJustinGasExpense(input: $input, condition: $condition) {
      id
      JustinGasExpenseName
      JustinGasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteJustinGasExpense = /* GraphQL */ `
  mutation DeleteJustinGasExpense(
    $input: DeleteJustinGasExpenseInput!
    $condition: ModelJustinGasExpenseConditionInput
  ) {
    deleteJustinGasExpense(input: $input, condition: $condition) {
      id
      JustinGasExpenseName
      JustinGasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createJustinExpense = /* GraphQL */ `
  mutation CreateJustinExpense(
    $input: CreateJustinExpenseInput!
    $condition: ModelJustinExpenseConditionInput
  ) {
    createJustinExpense(input: $input, condition: $condition) {
      id
      JustinExpenseName
      JustinExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateJustinExpense = /* GraphQL */ `
  mutation UpdateJustinExpense(
    $input: UpdateJustinExpenseInput!
    $condition: ModelJustinExpenseConditionInput
  ) {
    updateJustinExpense(input: $input, condition: $condition) {
      id
      JustinExpenseName
      JustinExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteJustinExpense = /* GraphQL */ `
  mutation DeleteJustinExpense(
    $input: DeleteJustinExpenseInput!
    $condition: ModelJustinExpenseConditionInput
  ) {
    deleteJustinExpense(input: $input, condition: $condition) {
      id
      JustinExpenseName
      JustinExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createBriExpense = /* GraphQL */ `
  mutation CreateBriExpense(
    $input: CreateBriExpenseInput!
    $condition: ModelBriExpenseConditionInput
  ) {
    createBriExpense(input: $input, condition: $condition) {
      id
      BriExpenseName
      BriExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateBriExpense = /* GraphQL */ `
  mutation UpdateBriExpense(
    $input: UpdateBriExpenseInput!
    $condition: ModelBriExpenseConditionInput
  ) {
    updateBriExpense(input: $input, condition: $condition) {
      id
      BriExpenseName
      BriExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteBriExpense = /* GraphQL */ `
  mutation DeleteBriExpense(
    $input: DeleteBriExpenseInput!
    $condition: ModelBriExpenseConditionInput
  ) {
    deleteBriExpense(input: $input, condition: $condition) {
      id
      BriExpenseName
      BriExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createIanFund = /* GraphQL */ `
  mutation CreateIanFund(
    $input: CreateIanFundInput!
    $condition: ModelIanFundConditionInput
  ) {
    createIanFund(input: $input, condition: $condition) {
      id
      IanFundName
      IanFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateIanFund = /* GraphQL */ `
  mutation UpdateIanFund(
    $input: UpdateIanFundInput!
    $condition: ModelIanFundConditionInput
  ) {
    updateIanFund(input: $input, condition: $condition) {
      id
      IanFundName
      IanFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteIanFund = /* GraphQL */ `
  mutation DeleteIanFund(
    $input: DeleteIanFundInput!
    $condition: ModelIanFundConditionInput
  ) {
    deleteIanFund(input: $input, condition: $condition) {
      id
      IanFundName
      IanFundAmount
      createdAt
      updatedAt
    }
  }
`;
export const createJustinIanExpense = /* GraphQL */ `
  mutation CreateJustinIanExpense(
    $input: CreateJustinIanExpenseInput!
    $condition: ModelJustinIanExpenseConditionInput
  ) {
    createJustinIanExpense(input: $input, condition: $condition) {
      id
      JustinIanName
      JustinIanExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateJustinIanExpense = /* GraphQL */ `
  mutation UpdateJustinIanExpense(
    $input: UpdateJustinIanExpenseInput!
    $condition: ModelJustinIanExpenseConditionInput
  ) {
    updateJustinIanExpense(input: $input, condition: $condition) {
      id
      JustinIanName
      JustinIanExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteJustinIanExpense = /* GraphQL */ `
  mutation DeleteJustinIanExpense(
    $input: DeleteJustinIanExpenseInput!
    $condition: ModelJustinIanExpenseConditionInput
  ) {
    deleteJustinIanExpense(input: $input, condition: $condition) {
      id
      JustinIanName
      JustinIanExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createBriIanExpense = /* GraphQL */ `
  mutation CreateBriIanExpense(
    $input: CreateBriIanExpenseInput!
    $condition: ModelBriIanExpenseConditionInput
  ) {
    createBriIanExpense(input: $input, condition: $condition) {
      id
      BriIanExpenseName
      BriIanExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateBriIanExpense = /* GraphQL */ `
  mutation UpdateBriIanExpense(
    $input: UpdateBriIanExpenseInput!
    $condition: ModelBriIanExpenseConditionInput
  ) {
    updateBriIanExpense(input: $input, condition: $condition) {
      id
      BriIanExpenseName
      BriIanExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteBriIanExpense = /* GraphQL */ `
  mutation DeleteBriIanExpense(
    $input: DeleteBriIanExpenseInput!
    $condition: ModelBriIanExpenseConditionInput
  ) {
    deleteBriIanExpense(input: $input, condition: $condition) {
      id
      BriIanExpenseName
      BriIanExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createBriExtrasExpense = /* GraphQL */ `
  mutation CreateBriExtrasExpense(
    $input: CreateBriExtrasExpenseInput!
    $condition: ModelBriExtrasExpenseConditionInput
  ) {
    createBriExtrasExpense(input: $input, condition: $condition) {
      id
      BriExtrasExpenseName
      BriExtrasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateBriExtrasExpense = /* GraphQL */ `
  mutation UpdateBriExtrasExpense(
    $input: UpdateBriExtrasExpenseInput!
    $condition: ModelBriExtrasExpenseConditionInput
  ) {
    updateBriExtrasExpense(input: $input, condition: $condition) {
      id
      BriExtrasExpenseName
      BriExtrasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteBriExtrasExpense = /* GraphQL */ `
  mutation DeleteBriExtrasExpense(
    $input: DeleteBriExtrasExpenseInput!
    $condition: ModelBriExtrasExpenseConditionInput
  ) {
    deleteBriExtrasExpense(input: $input, condition: $condition) {
      id
      BriExtrasExpenseName
      BriExtrasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createJustinExtrasExpense = /* GraphQL */ `
  mutation CreateJustinExtrasExpense(
    $input: CreateJustinExtrasExpenseInput!
    $condition: ModelJustinExtrasExpenseConditionInput
  ) {
    createJustinExtrasExpense(input: $input, condition: $condition) {
      id
      JustinExtrasName
      JustinExtrasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateJustinExtrasExpense = /* GraphQL */ `
  mutation UpdateJustinExtrasExpense(
    $input: UpdateJustinExtrasExpenseInput!
    $condition: ModelJustinExtrasExpenseConditionInput
  ) {
    updateJustinExtrasExpense(input: $input, condition: $condition) {
      id
      JustinExtrasName
      JustinExtrasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteJustinExtrasExpense = /* GraphQL */ `
  mutation DeleteJustinExtrasExpense(
    $input: DeleteJustinExtrasExpenseInput!
    $condition: ModelJustinExtrasExpenseConditionInput
  ) {
    deleteJustinExtrasExpense(input: $input, condition: $condition) {
      id
      JustinExtrasName
      JustinExtrasExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createExtrasFund = /* GraphQL */ `
  mutation CreateExtrasFund(
    $input: CreateExtrasFundInput!
    $condition: ModelExtrasFundConditionInput
  ) {
    createExtrasFund(input: $input, condition: $condition) {
      id
      ExtrasFundName
      ExtrasFundExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateExtrasFund = /* GraphQL */ `
  mutation UpdateExtrasFund(
    $input: UpdateExtrasFundInput!
    $condition: ModelExtrasFundConditionInput
  ) {
    updateExtrasFund(input: $input, condition: $condition) {
      id
      ExtrasFundName
      ExtrasFundExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteExtrasFund = /* GraphQL */ `
  mutation DeleteExtrasFund(
    $input: DeleteExtrasFundInput!
    $condition: ModelExtrasFundConditionInput
  ) {
    deleteExtrasFund(input: $input, condition: $condition) {
      id
      ExtrasFundName
      ExtrasFundExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createEmergencySavingsAmount = /* GraphQL */ `
  mutation CreateEmergencySavingsAmount(
    $input: CreateEmergencySavingsAmountInput!
    $condition: ModelEmergencySavingsAmountConditionInput
  ) {
    createEmergencySavingsAmount(input: $input, condition: $condition) {
      id
      EmergencySavingsName
      totalEmergencySavings
      createdAt
      updatedAt
    }
  }
`;
export const updateEmergencySavingsAmount = /* GraphQL */ `
  mutation UpdateEmergencySavingsAmount(
    $input: UpdateEmergencySavingsAmountInput!
    $condition: ModelEmergencySavingsAmountConditionInput
  ) {
    updateEmergencySavingsAmount(input: $input, condition: $condition) {
      id
      EmergencySavingsName
      totalEmergencySavings
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmergencySavingsAmount = /* GraphQL */ `
  mutation DeleteEmergencySavingsAmount(
    $input: DeleteEmergencySavingsAmountInput!
    $condition: ModelEmergencySavingsAmountConditionInput
  ) {
    deleteEmergencySavingsAmount(input: $input, condition: $condition) {
      id
      EmergencySavingsName
      totalEmergencySavings
      createdAt
      updatedAt
    }
  }
`;
export const createBriEmergencyExpense = /* GraphQL */ `
  mutation CreateBriEmergencyExpense(
    $input: CreateBriEmergencyExpenseInput!
    $condition: ModelBriEmergencyExpenseConditionInput
  ) {
    createBriEmergencyExpense(input: $input, condition: $condition) {
      id
      BriEmergencyExpenseName
      BriEmergencyExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateBriEmergencyExpense = /* GraphQL */ `
  mutation UpdateBriEmergencyExpense(
    $input: UpdateBriEmergencyExpenseInput!
    $condition: ModelBriEmergencyExpenseConditionInput
  ) {
    updateBriEmergencyExpense(input: $input, condition: $condition) {
      id
      BriEmergencyExpenseName
      BriEmergencyExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteBriEmergencyExpense = /* GraphQL */ `
  mutation DeleteBriEmergencyExpense(
    $input: DeleteBriEmergencyExpenseInput!
    $condition: ModelBriEmergencyExpenseConditionInput
  ) {
    deleteBriEmergencyExpense(input: $input, condition: $condition) {
      id
      BriEmergencyExpenseName
      BriEmergencyExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createJustinEmergencyExpense = /* GraphQL */ `
  mutation CreateJustinEmergencyExpense(
    $input: CreateJustinEmergencyExpenseInput!
    $condition: ModelJustinEmergencyExpenseConditionInput
  ) {
    createJustinEmergencyExpense(input: $input, condition: $condition) {
      id
      JustinEmergencyExpenseName
      JustinEmergencyExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateJustinEmergencyExpense = /* GraphQL */ `
  mutation UpdateJustinEmergencyExpense(
    $input: UpdateJustinEmergencyExpenseInput!
    $condition: ModelJustinEmergencyExpenseConditionInput
  ) {
    updateJustinEmergencyExpense(input: $input, condition: $condition) {
      id
      JustinEmergencyExpenseName
      JustinEmergencyExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteJustinEmergencyExpense = /* GraphQL */ `
  mutation DeleteJustinEmergencyExpense(
    $input: DeleteJustinEmergencyExpenseInput!
    $condition: ModelJustinEmergencyExpenseConditionInput
  ) {
    deleteJustinEmergencyExpense(input: $input, condition: $condition) {
      id
      JustinEmergencyExpenseName
      JustinEmergencyExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createBill = /* GraphQL */ `
  mutation CreateBill(
    $input: CreateBillInput!
    $condition: ModelBillConditionInput
  ) {
    createBill(input: $input, condition: $condition) {
      id
      BillName
      BillAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateBill = /* GraphQL */ `
  mutation UpdateBill(
    $input: UpdateBillInput!
    $condition: ModelBillConditionInput
  ) {
    updateBill(input: $input, condition: $condition) {
      id
      BillName
      BillAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteBill = /* GraphQL */ `
  mutation DeleteBill(
    $input: DeleteBillInput!
    $condition: ModelBillConditionInput
  ) {
    deleteBill(input: $input, condition: $condition) {
      id
      BillName
      BillAmount
      createdAt
      updatedAt
    }
  }
`;
export const createTravelAmount = /* GraphQL */ `
  mutation CreateTravelAmount(
    $input: CreateTravelAmountInput!
    $condition: ModelTravelAmountConditionInput
  ) {
    createTravelAmount(input: $input, condition: $condition) {
      id
      TravelName
      TravelAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateTravelAmount = /* GraphQL */ `
  mutation UpdateTravelAmount(
    $input: UpdateTravelAmountInput!
    $condition: ModelTravelAmountConditionInput
  ) {
    updateTravelAmount(input: $input, condition: $condition) {
      id
      TravelName
      TravelAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteTravelAmount = /* GraphQL */ `
  mutation DeleteTravelAmount(
    $input: DeleteTravelAmountInput!
    $condition: ModelTravelAmountConditionInput
  ) {
    deleteTravelAmount(input: $input, condition: $condition) {
      id
      TravelName
      TravelAmount
      createdAt
      updatedAt
    }
  }
`;
export const createBriTravelExpense = /* GraphQL */ `
  mutation CreateBriTravelExpense(
    $input: CreateBriTravelExpenseInput!
    $condition: ModelBriTravelExpenseConditionInput
  ) {
    createBriTravelExpense(input: $input, condition: $condition) {
      id
      BriTravelExpenseName
      BriTravelExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateBriTravelExpense = /* GraphQL */ `
  mutation UpdateBriTravelExpense(
    $input: UpdateBriTravelExpenseInput!
    $condition: ModelBriTravelExpenseConditionInput
  ) {
    updateBriTravelExpense(input: $input, condition: $condition) {
      id
      BriTravelExpenseName
      BriTravelExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteBriTravelExpense = /* GraphQL */ `
  mutation DeleteBriTravelExpense(
    $input: DeleteBriTravelExpenseInput!
    $condition: ModelBriTravelExpenseConditionInput
  ) {
    deleteBriTravelExpense(input: $input, condition: $condition) {
      id
      BriTravelExpenseName
      BriTravelExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const createJustinTravelExpense = /* GraphQL */ `
  mutation CreateJustinTravelExpense(
    $input: CreateJustinTravelExpenseInput!
    $condition: ModelJustinTravelExpenseConditionInput
  ) {
    createJustinTravelExpense(input: $input, condition: $condition) {
      id
      JustinTravelExpenseName
      JustinTravelExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateJustinTravelExpense = /* GraphQL */ `
  mutation UpdateJustinTravelExpense(
    $input: UpdateJustinTravelExpenseInput!
    $condition: ModelJustinTravelExpenseConditionInput
  ) {
    updateJustinTravelExpense(input: $input, condition: $condition) {
      id
      JustinTravelExpenseName
      JustinTravelExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
export const deleteJustinTravelExpense = /* GraphQL */ `
  mutation DeleteJustinTravelExpense(
    $input: DeleteJustinTravelExpenseInput!
    $condition: ModelJustinTravelExpenseConditionInput
  ) {
    deleteJustinTravelExpense(input: $input, condition: $condition) {
      id
      JustinTravelExpenseName
      JustinTravelExpenseAmount
      createdAt
      updatedAt
    }
  }
`;
