import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {API} from "aws-amplify";
import {Flex, View} from "@aws-amplify/ui-react";
import {Button, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import {calculateBillsTotal, convertDate, currencyFormat} from "../calculationFunctions";
import {createBill, deleteBill} from "../../graphql/mutations";
import {listBills} from "../../graphql/queries";

let totalBillsAmount = 0;
let runningBillAmount = 1142;

export default function BillsTable() {
    const [billExpense, setBillsExpenses] = useState([]);
    const [value, setValue] = React.useState('1');

    useEffect(() => {
        fetchTotalBills()
    }, []);

    async function fetchTotalBills() {
        let billsApiData = await API.graphql({query: listBills});
        let amountsFromBillsAPI = billsApiData.data.listBills.items;
        setBillsExpenses(amountsFromBillsAPI);
        totalBillsAmount = calculateBillsTotal(amountsFromBillsAPI);
        runningBillAmount = runningBillAmount + totalBillsAmount;
    }

    async function createBillExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            BillName: form.get("BillName"),
            BillAmount: form.get("BillAmount"),

        };
        await API.graphql({
            query: createBill,
            variables: {input: data},
        });
        await fetchTotalBills()
        event.target.reset();
    }

    async function deleteBillExpense({id}) {
        const newExpense = billExpense.filter((expense) => expense.id !== id);
        setBillsExpenses(newExpense);
        await API.graphql({
            query: deleteBill,
            variables: {input: {id}},
        });
        await fetchTotalBills()
    }

    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                </Box>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="1">
                    <Typography textAlign="center">
                        Total amount of bills left to be charged <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(runningBillAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createBillExpense}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="BillName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="BillAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {billExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.BillAmount || row.BillName}
                                        >
                                            <TableCell align="left">{row.BillName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell
                                                align="center">{currencyFormat(row.BillAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteBillExpense(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br></br></div>
                <Typography textAlign="center">Car Insurance
                    <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>  {currencyFormat(276)}</Typography>
                </Typography>
                <div><br></br></div>
                <Typography textAlign="center">Cell Phones
                    <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>  {currencyFormat(112)}</Typography>
                </Typography>
                <div><br></br></div>
                <Typography textAlign="center">Life Insurance
                    <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>  {currencyFormat(200)}</Typography>
                </Typography>
                <div><br></br></div>
                <Typography textAlign="center">Streaming Services
                    <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>  {currencyFormat(13.72)}</Typography>
                </Typography>
                <div><br></br></div>
                <Typography textAlign="center">Amazon
                    <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>  {currencyFormat(33.08)}</Typography>
                </Typography>
                <div><br></br></div>
                <Typography textAlign="center">Tolls
                    <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>  {currencyFormat(130)}</Typography>
                </Typography>
                <div><br></br></div>
                <Typography textAlign="center">Internet
                    <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>  {currencyFormat(56)}</Typography>
                </Typography>
                <div><br></br></div>
                <Typography textAlign="center">4Engergy Electric
                    <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>  {currencyFormat(245)}</Typography>
                </Typography>
                <div><br></br></div>
                <Typography textAlign="center">Atmos Gas
                    <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>  {currencyFormat(120)}</Typography>
                </Typography>
                <div><br></br></div>
                <Typography textAlign="center">Crossroads Water/Sewer/Trash
                    <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>  {currencyFormat(80)}</Typography>
                </Typography>
                <div><br></br></div>
            </TabContext>
        </Box>
    );
}