export function calculateBriGroceryTotal(items) {
    let totalBriGroceryExpenseAmounts = 0;
    let briExpenseAmounts = items.map((expense) => (
        expense.BriGroceriesExpenseAmount
    ))
    for (let i = 0; i < briExpenseAmounts.length; i++) {
        {
            totalBriGroceryExpenseAmounts += briExpenseAmounts[i]
        }
    }
    return (totalBriGroceryExpenseAmounts)
}

export function calculateBillTotal(items) {
    let totalBillAmounts = 0;
    let billEntryAmounts = items.map((expense) => (
        expense.BillAmount
    ))
    for (let i = 0; i < billEntryAmounts.length; i++) {
        {
            totalBillAmounts += billEntryAmounts[i]
        }
    }
    return (totalBillAmounts)
}

export function calculateGroceryFundTotal(items) {
    let totalGroceryFundAmounts = 0;
    let fundAmounts = items.map((expense) => (
        expense.GroceriesFundAmount
    ))
    for (let i = 0; i < fundAmounts.length; i++) {
        {
            totalGroceryFundAmounts += fundAmounts[i]
        }
    }
    return (totalGroceryFundAmounts)
}

export function calculateJustinGroceryTotal(items) {
    let totalJustinGroceryExpenseAmounts = 0;
    let justinExpenseAmounts = items.map((expense) => (
        expense.JustinGroceriesExpenseAmount
    ))
    for (let i = 0; i < justinExpenseAmounts.length; i++) {
        {
            totalJustinGroceryExpenseAmounts += justinExpenseAmounts[i]
        }
    }
    return (totalJustinGroceryExpenseAmounts)
}

export function calculateBriEntertainmentTotal(items) {
    let totalBriEntertainmentExpenseAmounts = 0;
    let briExpenseAmounts = items.map((expense) => (
        expense.BriEntertainmentExpenseAmount
    ))
    for (let i = 0; i < briExpenseAmounts.length; i++) {
        {
            totalBriEntertainmentExpenseAmounts += briExpenseAmounts[i]
        }
    }
    return (totalBriEntertainmentExpenseAmounts)
}

export function calculateJustinEntertainmentTotal(items) {
    let totalJustinEntertainmentExpenseAmounts = 0;
    let justinExpenseAmounts = items.map((expense) => (
        expense.JustinEntertainmentExpenseAmount
    ))
    for (let i = 0; i < justinExpenseAmounts.length; i++) {
        {
            totalJustinEntertainmentExpenseAmounts += justinExpenseAmounts[i]
        }
    }
    return (totalJustinEntertainmentExpenseAmounts)
}

export function calculateEmergencyFund(items) {
    let totalEntertainmentFund = 0;
    let fundAmounts = items.map((expense) => (
        expense.EntertainmentFundAmount
    ))
    for (let i = 0; i < fundAmounts.length; i++) {
        {
            totalEntertainmentFund += fundAmounts[i]
        }
    }
    return (totalEntertainmentFund)
}

export function calculateBriExpenseTotal(items) {
    let totalBriExpenseAmounts = 0;
    let briExpenseAmounts = items.map((expense) => (
        expense.BriExpenseAmount
    ))
    for (let i = 0; i < briExpenseAmounts.length; i++) {
        {
            totalBriExpenseAmounts += briExpenseAmounts[i]
        }
    }
    return (totalBriExpenseAmounts)
}

export function calculateJustinExpenseTotal(items) {
    let totalJustinExpenseAmounts = 0;
    let justinExpenseAmounts = items.map((expense) => (
        expense.JustinExpenseAmount
    ))
    for (let i = 0; i < justinExpenseAmounts.length; i++) {
        {
            totalJustinExpenseAmounts += justinExpenseAmounts[i]
        }
    }
    return (totalJustinExpenseAmounts)
}

export function calculateEmergencyExpenseTotal(items) {
    let totalEmergencyExpenseAmounts = 0;
    let emergencyExpenseAmounts = items.map((expense) => (
        expense.totalEmergencySavings
    ))
    for (let i = 0; i < emergencyExpenseAmounts.length; i++) {
        {
            totalEmergencyExpenseAmounts += emergencyExpenseAmounts[i]
        }
    }
    return (totalEmergencyExpenseAmounts)
}

export function calculateTravelExpenseTotal(items) {
    let totalTravelExpenseAmounts = 0;
    let emergencyTravelAmounts = items.map((expense) => (
        expense.TravelAmount
    ))
    for (let i = 0; i < emergencyTravelAmounts.length; i++) {
        {
            totalTravelExpenseAmounts += emergencyTravelAmounts[i]
        }
    }
    return (totalTravelExpenseAmounts)
}

export function calculateBriTravelExpenseTotal(items) {
    let totalBriTravelExpenseAmounts = 0;
    let briEmergencyTravelAmounts = items.map((expense) => (
        expense.BriTravelExpenseAmount
    ))
    for (let i = 0; i < briEmergencyTravelAmounts.length; i++) {
        {
            totalBriTravelExpenseAmounts += briEmergencyTravelAmounts[i]
        }
    }
    return (totalBriTravelExpenseAmounts)
}

export function calculateBriEmergencyExpenseTotal(items) {
    let totalBriEmergencyExpenseAmounts = 0;
    let briEmergencyAmounts = items.map((expense) => (
        expense.BriEmergencyExpenseAmount
    ))
    for (let i = 0; i < briEmergencyAmounts.length; i++) {
        {
            totalBriEmergencyExpenseAmounts += briEmergencyAmounts[i]
        }
    }
    return (totalBriEmergencyExpenseAmounts)
}

export function calculateJustinEmergencyExpenseTotal(items) {
    let totalJustinEmergencyExpenseAmounts = 0;
    let justinEmergencyAmounts = items.map((expense) => (
        expense.JustinEmergencyExpenseAmount
    ))
    for (let i = 0; i < justinEmergencyAmounts.length; i++) {
        {
            totalJustinEmergencyExpenseAmounts += justinEmergencyAmounts[i]
        }
    }
    return (totalJustinEmergencyExpenseAmounts)
}

export function calculateJustinTravelExpenseTotal(items) {
    let totalJustinTravelExpenseAmounts = 0;
    let justinEmergencyTravelAmounts = items.map((expense) => (
        expense.JustinTravelExpenseAmount
    ))
    for (let i = 0; i < justinEmergencyTravelAmounts.length; i++) {
        {
            totalJustinTravelExpenseAmounts += justinEmergencyTravelAmounts[i]
        }
    }
    return (totalJustinTravelExpenseAmounts)
}

export function calculateBriGasTotal(items) {
    let totalBriGasExpenseAmounts = 0;
    let briExpenseAmounts = items.map((expense) => (
        expense.BriGasExpenseAmount
    ))
    for (let i = 0; i < briExpenseAmounts.length; i++) {
        {
            totalBriGasExpenseAmounts += briExpenseAmounts[i]
        }
    }
    return (totalBriGasExpenseAmounts)
}

export function calculateJustinGasTotal(items) {
    let totalJustinGasExpenseAmounts = 0;
    let justinExpenseAmounts = items.map((expense) => (
        expense.JustinGasExpenseAmount
    ))
    for (let i = 0; i < justinExpenseAmounts.length; i++) {
        {
            totalJustinGasExpenseAmounts += justinExpenseAmounts[i]
        }
    }
    return (totalJustinGasExpenseAmounts)
}

export function calculateGasFundTotal(items) {
    let totalGasFundAmounts = 0;
    let gasFundAmounts = items.map((expense) => (
        expense.GasFundAmount
    ))
    for (let i = 0; i < gasFundAmounts.length; i++) {
        {
            totalGasFundAmounts += gasFundAmounts[i]
        }
    }
    return (totalGasFundAmounts)
}

export function calculateBriExtrasTotal(items) {
    let totalBriExtrasExpenseAmounts = 0;
    let briExpenseAmounts = items.map((expense) => (
        expense.BriExtrasExpenseAmount
    ))
    for (let i = 0; i < briExpenseAmounts.length; i++) {
        {
            totalBriExtrasExpenseAmounts += briExpenseAmounts[i]
        }
    }
    return (totalBriExtrasExpenseAmounts)
}

export function calculateJustinExtrasTotal(items) {
    let totalJustinExtrasExpenseAmounts = 0;
    let justinExpenseAmounts = items.map((expense) => (
        expense.JustinExtrasExpenseAmount
    ))
    for (let i = 0; i < justinExpenseAmounts.length; i++) {
        {
            totalJustinExtrasExpenseAmounts += justinExpenseAmounts[i]
        }
    }
    return (totalJustinExtrasExpenseAmounts)
}

export function calculateExtraFundTotal(items) {
    let totalExtraFundAmounts = 0;
    let extraFundAmount = items.map((expense) => (
        expense.ExtrasFundExpenseAmount
    ))
    for (let i = 0; i < extraFundAmount.length; i++) {
        {
            totalExtraFundAmounts += extraFundAmount[i]
        }
    }
    return (totalExtraFundAmounts)
}

export function calculateBriIanAllowanceTotal(items) {
    let totalBriIanAllowanceExpenseAmounts = 0;
    let briExpenseAmounts = items.map((expense) => (
        expense.BriIanExpenseAmount
    ))
    for (let i = 0; i < briExpenseAmounts.length; i++) {
        {
            totalBriIanAllowanceExpenseAmounts += briExpenseAmounts[i]
        }
    }
    return (totalBriIanAllowanceExpenseAmounts)
}

export function calculateJustinIanAllowanceTotal(items) {
    let totalJustinianAllowanceExpenseAmounts = 0;
    let justinExpenseAmounts = items.map((expense) => (
        expense.JustinIanExpenseAmount
    ))
    for (let i = 0; i < justinExpenseAmounts.length; i++) {
        {
            totalJustinianAllowanceExpenseAmounts += justinExpenseAmounts[i]
        }
    }
    return (totalJustinianAllowanceExpenseAmounts)
}

export function calculateIanFundTotal(items) {
    let totalIanFundAmounts = 0;
    let ianFundAmounts = items.map((expense) => (
        expense.IanFundAmount
    ))
    for (let i = 0; i < ianFundAmounts.length; i++) {
        {
            totalIanFundAmounts += ianFundAmounts[i]
        }
    }
    return (totalIanFundAmounts)
}

export function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function convertDate(date) {
    return new Date(date).toLocaleDateString('en-US');
}

export function calculateBillsTotal(items) {
    let totalBillsAmounts = 0;
    let billAmounts = items.map((expense) => (
        expense.BillAmount
    ))
    for (let i = 0; i < billAmounts.length; i++) {
        {
            totalBillsAmounts += billAmounts[i]
        }
    }
    return (totalBillsAmounts)
}

