import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {API} from "aws-amplify";
import {
    listBriIanExpenses, listIanFunds,
    listJustinIanExpenses
} from "../../graphql/queries";
import {
    createBriIanExpense,
    createJustinIanExpense,
    deleteJustinIanExpense,
    deleteBriIanExpense, createIanFund, deleteIanFund
} from "../../graphql/mutations";
import {Flex, View} from "@aws-amplify/ui-react";
import {Button, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
    calculateBriIanAllowanceTotal, calculateIanFundTotal,
    calculateJustinIanAllowanceTotal,
    convertDate,
    currencyFormat
} from "../calculationFunctions";

let justinIanAllowanceAmount = 0;
let briIanAllowanceAmount = 0;
let ianAllowanceAmount = 0;
let ianFundAmount = 0;

export default function IanAllowanceTable() {
    const [ianAllowanceBriExpense, setIanAllowanceBriExpenses] = useState([]);
    const [ianAllowanceJustinExpense, setIanAllowanceJustinExpenses] = useState([]);
    const [ianFund, setIanFund] = useState([]);
    const [value, setValue] = React.useState('1');

    useEffect(() => {
        fetchTotalAmountofIanAllowance()
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalAmountofIanAllowance() {
        let briApiData = await API.graphql({query: listBriIanExpenses});
        let amountsFromBriAPI = briApiData.data.listBriIanExpenses.items;
        let justinApiData = await API.graphql({query: listJustinIanExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinIanExpenses.items;
        let ianFundApiData = await API.graphql({query: listIanFunds});
        let amountsFromIanFundAPI = ianFundApiData.data.listIanFunds.items;
        setIanAllowanceBriExpenses(amountsFromBriAPI);
        setIanFund(amountsFromIanFundAPI);
        setIanAllowanceJustinExpenses(amountsFromJustinAPI);
        justinIanAllowanceAmount = calculateJustinIanAllowanceTotal(amountsFromJustinAPI);
        ianFundAmount = calculateIanFundTotal(amountsFromIanFundAPI);
        briIanAllowanceAmount = calculateBriIanAllowanceTotal(amountsFromBriAPI);
        ianAllowanceAmount = 36 + justinIanAllowanceAmount + briIanAllowanceAmount + ianFundAmount;
    }

    async function createIanFundEntry(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            IanFundName: form.get("IanFundName"),
            IanFundAmount: form.get("IanFundAmount"),
        };
        await API.graphql({
            query: createIanFund,
            variables: {input: data},
        });
        await fetchTotalAmountofIanAllowance()
        event.target.reset();
    }

    async function createBriExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            BriIanExpenseName: form.get("BriIanExpenseName"),
            BriIanExpenseAmount: form.get("BriIanExpenseAmount"),
        };
        await API.graphql({
            query: createBriIanExpense,
            variables: {input: data},
        });
        await fetchTotalAmountofIanAllowance()
        event.target.reset();
    }

    async function createJustinExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            JustinIanName: form.get("JustinIanName"),
            JustinIanExpenseAmount: form.get("JustinIanExpenseAmount"),
        };
        await API.graphql({
            query: createJustinIanExpense,
            variables: {input: data},
        });
        await fetchTotalAmountofIanAllowance()
        event.target.reset();
    }

    async function deleteIanFundEntry({id}) {
        const newExpense = ianFund.filter((expense) => expense.id !== id);
        setIanFund(newExpense);
        await API.graphql({
            query: deleteIanFund,
            variables: {input: {id}},
        });
        await fetchTotalAmountofIanAllowance()
    }

    async function deleteBriExpense({id}) {
        const newExpense = ianAllowanceBriExpense.filter((expense) => expense.id !== id);
        setIanAllowanceBriExpenses(newExpense);
        await API.graphql({
            query: deleteBriIanExpense,
            variables: {input: {id}},
        });
        await fetchTotalAmountofIanAllowance()
    }

    async function deleteJustinExpense({id}) {
        const newExpense = ianAllowanceJustinExpense.filter((expense) => expense.id !== id);
        setIanAllowanceJustinExpenses(newExpense);
        await API.graphql({
            query: deleteJustinIanExpense,
            variables: {input: {id}},
        });
        await fetchTotalAmountofIanAllowance()
    }

    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Ian's Money" value="1"/>
                        <Tab label="Bri" value="2"/>
                        <Tab label="Justin" value="3"/>
                    </TabList>
                </Box>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="1">
                    <Typography textAlign="center">Ian's Total Money<br/> for the month is
                        <Typography sx={{
                            fontWeight: 'bold',
                            display:'inline'
                        }}>  {currencyFormat(ianAllowanceAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createIanFundEntry}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="IanFundName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="IanFundAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ianFund.map((row) => (
                                        <TableRow
                                            key={row.id || row.IanFundAmount || row.IanFundName}
                                        >
                                            <TableCell align="left">{row.IanFundName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.IanFundAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteIanFundEntry(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="2">
                    <Typography textAlign="center">
                        Total amount Bri has spent <Typography sx={{
                        fontWeight: 'bold',
                        display:'inline'
                    }}>{currencyFormat(-briIanAllowanceAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createBriExpense}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="BriIanExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="BriIanExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ianAllowanceBriExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.BriIanExpenseAmount || row.BriIanExpenseName}
                                        >
                                            <TableCell align="left">{row.BriIanExpenseName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.BriIanExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteBriExpense(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="3">
                    <Typography textAlign="center">
                        Total amount Justin has spent <Typography sx={{
                        fontWeight: 'bold',
                        display:'inline'
                    }}>{currencyFormat(-justinIanAllowanceAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createJustinExpense}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="JustinIanName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="JustinIanExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ianAllowanceJustinExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.JustinIanExpenseAmount || row.JustinIanName}
                                        >
                                            <TableCell align="left">{row.JustinIanName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.JustinIanExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteJustinExpense(row)}>Delete</Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br></br></div>
                <Typography textAlign="center">Amount to carry over at the end<br/> of the month is
                    <Typography sx={{
                        fontWeight: 'bold',
                        display:'inline'
                    }}>  {currencyFormat(ianAllowanceAmount)}</Typography>
                </Typography>
                <div><br></br></div>
            </TabContext>
            <Typography textAlign="center">Monthly budget for Ian's Allowance is $36</Typography>
        </Box>
    );
}