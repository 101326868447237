import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {API} from "aws-amplify";
import {listBriExtrasExpenses, listExtrasFunds, listJustinExtrasExpenses} from "../../graphql/queries";
import {
    createBriExtrasExpense, createExtrasFund,
    createJustinExtrasExpense,
    deleteBriExtrasExpense, deleteExtrasFund,
    deleteJustinExtrasExpense
} from "../../graphql/mutations";
import {Flex, View} from "@aws-amplify/ui-react";
import {Button, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
    calculateBriExtrasTotal, calculateExtraFundTotal,
    calculateJustinExtrasTotal,
    convertDate,
    currencyFormat
} from "../calculationFunctions";

let justinExtrasAmount = 0;
let briExtrasAmount = 0;
let extraFund = 0;
let extrasAmount = 0;

export default function ExtrasTable() {
    const [extrasBriExpense, setExtrasBriExpenses] = useState([]);
    const [extrasJustinExpense, setExtrasJustinExpenses] = useState([]);
    const [extrasFund, setExtrasFund] = useState([]);
    const [value, setValue] = React.useState('1');

    useEffect(() => {
        fetchTotalAmountofExtras()
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalAmountofExtras() {
        let briApiData = await API.graphql({query: listBriExtrasExpenses});
        let amountsFromBriAPI = briApiData.data.listBriExtrasExpenses.items;
        let justinApiData = await API.graphql({query: listJustinExtrasExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinExtrasExpenses.items;
        let extraFundApiData = await API.graphql({query: listExtrasFunds});
        let amountsFromExtraFundAPI = extraFundApiData.data.listExtrasFunds.items;
        setExtrasFund(amountsFromExtraFundAPI);
        setExtrasBriExpenses(amountsFromBriAPI);
        setExtrasJustinExpenses(amountsFromJustinAPI);
        justinExtrasAmount = calculateJustinExtrasTotal(amountsFromJustinAPI);
        briExtrasAmount = calculateBriExtrasTotal(amountsFromBriAPI);
        extraFund = calculateExtraFundTotal(amountsFromExtraFundAPI);
        extrasAmount = 650 + justinExtrasAmount + briExtrasAmount + extraFund;
    }

    async function createBriExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            BriExtrasExpenseName: form.get("BriExtrasExpenseName"),
            BriExtrasExpenseAmount: form.get("BriExtrasExpenseAmount"),

        };
        await API.graphql({
            query: createBriExtrasExpense,
            variables: {input: data},
        });
        await fetchTotalAmountofExtras()
        event.target.reset();
    }

    async function createJustinExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            JustinExtrasName: form.get("JustinExtrasName"),
            JustinExtrasExpenseAmount: form.get("JustinExtrasExpenseAmount"),
        };
        await API.graphql({
            query: createJustinExtrasExpense,
            variables: {input: data},
        });
        await fetchTotalAmountofExtras()
        event.target.reset();
    }

    async function createExtraFund(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            ExtrasFundName: form.get("ExtrasFundName"),
            ExtrasFundExpenseAmount: form.get("ExtrasFundExpenseAmount"),
        };
        await API.graphql({
            query: createExtrasFund,
            variables: {input: data},
        });
        await fetchTotalAmountofExtras()
        event.target.reset();
    }

    async function deleteBriExpense({id}) {
        const newExpense = extrasBriExpense.filter((expense) => expense.id !== id);
        setExtrasBriExpenses(newExpense);
        await API.graphql({
            query: deleteBriExtrasExpense,
            variables: {input: {id}},
        });
        await fetchTotalAmountofExtras()
    }

    async function deleteExtraFund({id}) {
        const newExpense = extrasFund.filter((expense) => expense.id !== id);
        setExtrasFund(newExpense);
        await API.graphql({
            query: deleteExtrasFund,
            variables: {input: {id}},
        });
        await fetchTotalAmountofExtras()
    }

    async function deleteJustinExpense({id}) {
        const newExpense = extrasJustinExpense.filter((expense) => expense.id !== id);
        setExtrasJustinExpenses(newExpense);
        await API.graphql({
            query: deleteJustinExtrasExpense,
            variables: {input: {id}},
        });
        await fetchTotalAmountofExtras()
    }


    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Extra Fund" value="1"/>
                        <Tab label="Bri" value="2"/>
                        <Tab label="Justin" value="3"/>
                    </TabList>
                </Box>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="1">

                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createExtraFund}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="ExtrasFundName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="ExtrasFundExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {extrasFund.map((row) => (
                                        <TableRow
                                            key={row.id || row.ExtrasFundExpenseAmount || row.ExtrasFundName}
                                        >
                                            <TableCell align="left">{row.ExtrasFundName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.ExtrasFundExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteExtraFund(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="2">
                    <Typography textAlign="center">
                        Total amount Bri has spent <Typography sx={{
                        fontWeight: 'bold',
                        display:'inline'
                    }}>{currencyFormat(-briExtrasAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createBriExpense}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="BriExtrasExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="BriExtrasExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {extrasBriExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.BriExtrasExpenseAmount || row.BriExtrasExpenseName}
                                        >
                                            <TableCell align="left">{row.BriExtrasExpenseName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.BriExtrasExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteBriExpense(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="3">
                    <Typography textAlign="center">
                        Total amount Justin has spent <Typography sx={{
                        fontWeight: 'bold',
                        display:'inline'
                    }}>{currencyFormat(-justinExtrasAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createJustinExpense}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="JustinExtrasName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="JustinExtrasExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {extrasJustinExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.JustinExtrasExpenseAmount || row.JustinExtrasName}
                                        >
                                            <TableCell align="left">{row.JustinExtrasName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.JustinExtrasExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteJustinExpense(row)}>Delete</Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br></br></div>
                <Typography textAlign="center">Total amount is
                    <Typography sx={{
                        fontWeight: 'bold',
                        display:'inline'
                    }}>  {currencyFormat(extrasAmount)}</Typography>
                </Typography>
                <div><br></br></div>
            </TabContext>
            <Typography textAlign="center">***Monthly deposit for Extra's is $650</Typography>
            <div><br></br></div>
        </Box>
    );
}