import React, {useEffect, useState} from "react";
import "@aws-amplify/ui-react/styles.css";
import {AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from "@mui/material/transitions";
import TravelTable from "./TravelTable";
import {API} from "aws-amplify";
import {listBriTravelExpenses, listJustinTravelExpenses, listTravelAmounts} from "../../graphql/queries";
import {
    calculateBriTravelExpenseTotal,
    calculateJustinTravelExpenseTotal,
    calculateTravelExpenseTotal,
    currencyFormat
} from "../calculationFunctions";

let totalTravelAmount = 0;
let totalBriAmount = 0;
let totalJustinAmount = 0;
let finalTotal = 0

const TravelDialog = () => {

    const [travelExpenseOpen, setTravelExpenseOpen] = React.useState(false);
    const [travelAmount, setTravelAmount] = useState([]);
    const [JustinTravelExpense, setJustinTravelExpenses] = useState([]);
    const [BriTravelExpense, setBriTravelExpenses] = useState([]);

    const handleClickTravelExpenseOpen = () => {
        let name = setTravelExpenseOpen(true);
    };
    const handleTravelExpenseClose = () => {
        window.location.reload();
        setTravelExpenseOpen(false);
    };

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    useEffect(() => {
        fetchTravelFundItems()


    }, []);

    async function fetchTravelFundItems() {
        const apiData = await API.graphql({query: listTravelAmounts});
        const amountsFromAPI = apiData.data.listTravelAmounts.items;
        const apiBriData = await API.graphql({query: listBriTravelExpenses});
        const amountsFromBriAPI = apiBriData.data.listBriTravelExpenses.items;
        const apiJustinData = await API.graphql({query: listJustinTravelExpenses});
        const amountsFromJustinAPI = apiJustinData.data.listJustinTravelExpenses.items;
        setJustinTravelExpenses(amountsFromJustinAPI);
        setTravelAmount(amountsFromAPI);
        setBriTravelExpenses(amountsFromBriAPI);
        totalJustinAmount = calculateJustinTravelExpenseTotal(amountsFromJustinAPI);
        totalTravelAmount = calculateTravelExpenseTotal(amountsFromAPI);
        totalBriAmount = calculateBriTravelExpenseTotal(amountsFromBriAPI);
        finalTotal = totalTravelAmount + totalBriAmount + totalJustinAmount;
    }

    return (
        <div>
            <Box
                textAlign='center'>
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined" onClick={handleClickTravelExpenseOpen}>
                    <Typography sx={{fontSize: 14}}>Travel Fund<Typography sx={{fontSize: 14}}>{currencyFormat(finalTotal)} Total</Typography> </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={travelExpenseOpen}
                onClose={handleTravelExpenseClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleTravelExpenseClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Travel Fund
                        </Typography>
                    </Toolbar>
                </AppBar>
                <TravelTable/>
            </Dialog>
        </div>
    );
};
export default TravelDialog;


