import React, {useEffect, useState} from "react";
import "@aws-amplify/ui-react/styles.css";
import {API} from "aws-amplify";
import {listBriIanExpenses, listIanFunds, listJustinIanExpenses,} from "../../graphql/queries";
import {AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from "@mui/material/transitions";
import IanAllowance from "../../components/IanAllowance/IanAllowanceTable"
import {
    calculateBriIanAllowanceTotal, calculateIanFundTotal,
    calculateJustinIanAllowanceTotal,
    currencyFormat,
} from "../calculationFunctions";

let justinIanAllowanceAmount = 0;
let briIanAllowanceAmount = 0;
let ianAllowanceAmount = 0;
let ianFundAmount = 0;

const IanAllowanceDialog = () => {
    const [ianAllowanceOpen, setIanAllowanceOpen] = React.useState(false);
    const [close, setClose] = React.useState(true);
    const [ianFund, setIanFund] = useState([]);
    const [briIanAllowanceExpenses, setBriIanAllowanceExpenses] = useState([]);
    const [justinIanAllowanceExpenses, setJustinIanAllowanceExpenses] = useState([]);

    const handleClickIanAllowanceOpen = () => {
        let name = setIanAllowanceOpen(true);
    };

    const handleIanAllowanceClose = () => {
        window.location.reload();
        setIanAllowanceOpen(false);
    };

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    useEffect(() => {
        fetchTotalAmountofIanAllowance()

    }, []);


    async function fetchTotalAmountofIanAllowance() {
        let briApiData = await API.graphql({query: listBriIanExpenses});
        let amountsFromBriAPI = briApiData.data.listBriIanExpenses.items;
        let justinApiData = await API.graphql({query: listJustinIanExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinIanExpenses.items;
        let ianFundApiData = await API.graphql({query: listIanFunds});
        let amountsFromIanFundAPI = ianFundApiData.data.listIanFunds.items;
        setBriIanAllowanceExpenses(amountsFromBriAPI);
        setIanFund(amountsFromIanFundAPI);
        setJustinIanAllowanceExpenses(amountsFromJustinAPI);
        ianFundAmount = calculateIanFundTotal(amountsFromIanFundAPI);
        justinIanAllowanceAmount = calculateJustinIanAllowanceTotal(amountsFromJustinAPI);
        briIanAllowanceAmount = calculateBriIanAllowanceTotal(amountsFromBriAPI);
        ianAllowanceAmount = 36 + justinIanAllowanceAmount + briIanAllowanceAmount + ianFundAmount;
    }

    return (
        <div>
            <Box
                textAlign='center'>
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined" onClick={handleClickIanAllowanceOpen}>
                    <Typography sx={{fontSize: 14}}>Ian's Allowance<Typography sx={{fontSize: 14}}>{currencyFormat(ianAllowanceAmount)} Left</Typography> </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={ianAllowanceOpen}
                onClose={handleIanAllowanceClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleIanAllowanceClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Ian's Allowance
                        </Typography>
                    </Toolbar>
                </AppBar>
                <IanAllowance/>
            </Dialog>
        </div>
    );
};
export default IanAllowanceDialog;


