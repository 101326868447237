import React, {useEffect, useState} from "react";
import "@aws-amplify/ui-react/styles.css";
import {AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from "@mui/material/transitions";
import EmergencySavingsTable from "./EmergencySavingsTable";
import {API} from "aws-amplify";
import {
    listBriEmergencyExpenses,
    listEmergencySavingsAmounts,
    listJustinEmergencyExpenses
} from "../../graphql/queries";
import {
    calculateBriEmergencyExpenseTotal,
    calculateEmergencyExpenseTotal,
    calculateJustinEmergencyExpenseTotal,
    currencyFormat
} from "../calculationFunctions";

let totalAmount = 0;
let totalJustinAmount = 0;
let totalBriAmount = 0;
let finalTotalAmount = 0;

const EmergencySavingsDialog = () => {
    const [emergencySavingsExpenseOpen, setEmergencySavingsExpenseOpen] = React.useState(false);
    const [SavingsExpense, setSavingsExpenses] = useState([]);
    const [BriEmergencyExpense, setBriEmergencyExpenses] = useState([]);
    const [JustinEmergencyExpense, setJustinEmergencyExpenses] = useState([]);


    useEffect(() => {
        fetchEmergencySavingsItems()
    }, []);

    async function fetchEmergencySavingsItems() {
        const apiData = await API.graphql({query: listEmergencySavingsAmounts});
        const amountsFromAPI = apiData.data.listEmergencySavingsAmounts.items;
        const apiBriData = await API.graphql({query: listBriEmergencyExpenses});
        const amountsFromBriAPI = apiBriData.data.listBriEmergencyExpenses.items;
        const apiJustinData = await API.graphql({query: listJustinEmergencyExpenses});
        const amountsFromJustinAPI = apiJustinData.data.listJustinEmergencyExpenses.items;
        setSavingsExpenses(amountsFromAPI);
        setJustinEmergencyExpenses(amountsFromJustinAPI);
        setBriEmergencyExpenses(amountsFromBriAPI);
        totalAmount = calculateEmergencyExpenseTotal(amountsFromAPI);
        totalJustinAmount = calculateJustinEmergencyExpenseTotal(amountsFromJustinAPI);
        totalBriAmount = calculateBriEmergencyExpenseTotal(amountsFromBriAPI);
        finalTotalAmount = totalAmount + totalBriAmount + totalJustinAmount;
    }

    const handleClickEmergencyExpenseOpen = () => {
        let name = setEmergencySavingsExpenseOpen(true);
    };
    const handleEmergencySavingsExpenseClose = () => {
        window.location.reload();
        setEmergencySavingsExpenseOpen(false);
    };

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <div>
            <Box
                textAlign='center'>
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined" onClick={handleClickEmergencyExpenseOpen}>
                    <Typography sx={{fontSize: 14}}>Emergency Savings<Typography sx={{fontSize: 14}}>{currencyFormat(finalTotalAmount)} Total</Typography> </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={emergencySavingsExpenseOpen}
                onClose={handleEmergencySavingsExpenseClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleEmergencySavingsExpenseClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Emergency Savings
                        </Typography>
                    </Toolbar>
                </AppBar>
                <EmergencySavingsTable/>
            </Dialog>
        </div>
    );
};
export default EmergencySavingsDialog;


