import React, {useEffect, useState} from "react";
import "@aws-amplify/ui-react/styles.css";
import {API} from "aws-amplify";
import {listBriGasExpenses, listGasFunds, listJustinGasExpenses,} from "../../graphql/queries";
import {AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from "@mui/material/transitions";
import Gas from "../../components/Gas/GasTable"
import {
    calculateBriGasTotal,
    calculateGasFundTotal,
    calculateJustinGasTotal,
    currencyFormat,
} from "../calculationFunctions";

let justinGasAmount = 0;
let briGasAmount = 0;
let gasAmount = 0;
let gasFundAmount = 0;

const GasDialog = () => {
    const [gasOpen, setGasOpen] = React.useState(false);
    const [close, setClose] = React.useState(true);
    const [briGasExpenses, setBriGasExpenses] = useState([]);
    const [justinGasExpenses, setJustinGasExpenses] = useState([]);
    const [gasFund, setGasFund] = useState([]);


    const handleClickGasOpen = () => {
        let name = setGasOpen(true);
    };

    const handleGasClose = () => {
        window.location.reload();
        setGasOpen(false);
    };

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    useEffect(() => {
        fetchTotalAmountofGas()

    }, []);


    async function fetchTotalAmountofGas() {
        let briApiData = await API.graphql({query: listBriGasExpenses});
        let amountsFromBriAPI = briApiData.data.listBriGasExpenses.items;
        let justinApiData = await API.graphql({query: listJustinGasExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinGasExpenses.items;
        let gasFundApiData = await API.graphql({query: listGasFunds});
        let amountsFromGasFundAPI = gasFundApiData.data.listGasFunds.items;
        setBriGasExpenses(amountsFromBriAPI);
        setGasFund(amountsFromGasFundAPI);
        setJustinGasExpenses(amountsFromJustinAPI);
        gasFundAmount = calculateGasFundTotal(amountsFromGasFundAPI);
        justinGasAmount = calculateJustinGasTotal(amountsFromJustinAPI);
        briGasAmount = calculateBriGasTotal(amountsFromBriAPI);
        gasAmount = 300 + justinGasAmount + briGasAmount + gasFundAmount;
    }

    return (
        <div>
            <Box
                textAlign='center'>
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined" onClick={handleClickGasOpen}>
                    <Typography sx={{fontSize: 14}}>Gas<Typography sx={{fontSize: 14}}>{currencyFormat(gasAmount)} Left</Typography> </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={gasOpen}
                onClose={handleGasClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleGasClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Gas
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Gas/>
            </Dialog>
        </div>
    );
};
export default GasDialog;


