import React, {useEffect} from "react";
import "@aws-amplify/ui-react/styles.css";
import {AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from "@mui/material/transitions";
import BillsTable from "./BillsTable";
import {API} from "aws-amplify";
import {listBills} from "../../graphql/queries";
import {calculateBillTotal, currencyFormat} from "../calculationFunctions";

let billAmount = 0;

const BillsDialog = () => {
    const [billsOpen, setBillsOpen] = React.useState(false);
    const [billExpense, setBillExpense] = React.useState(false);
    const [close, setClose] = React.useState(true);

    useEffect(() => {
        fetchTotalAmountofBills()
    }, []);

    const handleClickExtrasOpen = () => {
        let name = setBillsOpen(true);
    };

    const handleExtrasClose = () => {
        window.location.reload();
        setBillsOpen(false);
    };

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    async function fetchTotalAmountofBills() {
        let billApiData = await API.graphql({query: listBills});
        let amountsFromBillAPI = billApiData.data.listBills.items;
        setBillExpense(amountsFromBillAPI);
        billAmount = 1261 + calculateBillTotal(amountsFromBillAPI);
    }

    return (
        <div>
            <Box
                textAlign='center'>
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined" onClick={handleClickExtrasOpen}>
                   <Typography sx={{fontSize: 14}}>Bills<Typography sx={{fontSize: 14}}>{currencyFormat(billAmount)} Left</Typography> </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={billsOpen}
                onClose={handleExtrasClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleExtrasClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Bills
                        </Typography>
                    </Toolbar>
                </AppBar>
                <BillsTable/>
            </Dialog>
        </div>
    );
};
export default BillsDialog;


