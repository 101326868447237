import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {API} from "aws-amplify";
import {listBriGroceryExpenses, listGroceryFunds, listJustinGroceryExpenses} from "../../graphql/queries";
import {
    createBriGroceryExpense, createGroceryFund,
    createJustinGroceryExpense,
    deleteBriGroceryExpense, deleteGroceryFund,
    deleteJustinGroceryExpense
} from "../../graphql/mutations";
import {Flex, View} from "@aws-amplify/ui-react";
import {Button, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
    calculateBriGroceryTotal, calculateGroceryFundTotal,
    calculateJustinGroceryTotal,
    convertDate,
    currencyFormat
} from "../calculationFunctions";

let justinGroceryAmount = 0;
let briGroceryAmount = 0;
let groceryRunningFund = 0;
let amountToCarryOver = 0;

export default function GroceryTable() {
    const [groceryBriExpense, setGroceryBriExpenses] = useState([]);
    const [groceryJustinExpense, setGroceryJustinExpenses] = useState([]);
    const [groceryFund, setGroceryFund] = useState([]);
    const [value, setValue] = React.useState('1');

    useEffect(() => {
        fetchTotalAmountofGroceries()
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalAmountofGroceries() {
        let briApiData = await API.graphql({query: listBriGroceryExpenses});
        let amountsFromBriAPI = briApiData.data.listBriGroceryExpenses.items;
        let justinApiData = await API.graphql({query: listJustinGroceryExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinGroceryExpenses.items;
        let fundApiData = await API.graphql({query: listGroceryFunds});
        let amountsFromFundAPI = fundApiData.data.listGroceryFunds.items;
        setGroceryFund(amountsFromFundAPI);
        setGroceryBriExpenses(amountsFromBriAPI);
        setGroceryJustinExpenses(amountsFromJustinAPI);
        groceryRunningFund = calculateGroceryFundTotal(amountsFromFundAPI);
        justinGroceryAmount = calculateJustinGroceryTotal(amountsFromJustinAPI);
        briGroceryAmount = calculateBriGroceryTotal(amountsFromBriAPI);
        amountToCarryOver = 950 + justinGroceryAmount + briGroceryAmount + groceryRunningFund;
    }

    async function createFundEntry(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            GroceriesFundName: form.get("GroceriesFundName"),
            GroceriesFundAmount: form.get("GroceriesFundAmount"),

        };
        await API.graphql({
            query: createGroceryFund, variables: {input: data},
        });
        await fetchTotalAmountofGroceries()
        event.target.reset();
    }

    async function createBriExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            BriGroceryExpenseName: form.get("BriGroceryExpenseName"),
            BriGroceriesExpenseAmount: form.get("BriGroceriesExpenseAmount"),

        };
        await API.graphql({
            query: createBriGroceryExpense, variables: {input: data},
        });
        await fetchTotalAmountofGroceries()
        event.target.reset();
    }

    async function createJustinExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            JustinGroceriesExpenseName: form.get("JustinGroceriesExpenseName"),
            JustinGroceriesExpenseAmount: form.get("JustinGroceriesExpenseAmount"),
        };
        await API.graphql({
            query: createJustinGroceryExpense, variables: {input: data},
        });
        fetchTotalAmountofGroceries()
        event.target.reset();
    }

    async function deleteGroceryExpenseFund({id}) {
        const newExpense = groceryFund.filter((expense) => expense.id !== id);
        setGroceryFund(newExpense);
        await API.graphql({
            query: deleteGroceryFund, variables: {input: {id}},
        });
        fetchTotalAmountofGroceries()
    }

    async function deleteBriExpense({id}) {
        const newExpense = groceryBriExpense.filter((expense) => expense.id !== id);
        setGroceryBriExpenses(newExpense);
        await API.graphql({
            query: deleteBriGroceryExpense, variables: {input: {id}},
        });
        fetchTotalAmountofGroceries()
    }

    async function deleteJustinExpense({id}) {
        const newExpense = groceryJustinExpense.filter((expense) => expense.id !== id);
        setGroceryJustinExpenses(newExpense);
        await API.graphql({
            query: deleteJustinGroceryExpense, variables: {input: {id}},
        });
        fetchTotalAmountofGroceries()
    }

    return (<Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Grocery Fund" value="1"/>
                        <Tab label="Bri" value="2"/>
                        <Tab label="Justin" value="3"/>
                    </TabList>
                </Box>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="1">
                    <Typography textAlign="center">Total Grocery Fund<br/> for the month is
                        <Typography sx={{
                            fontWeight: 'bold',
                            display: 'inline'
                        }}> {currencyFormat(amountToCarryOver)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createFundEntry}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="GroceriesFundName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="GroceriesFundAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groceryFund.map((row) => (<TableRow
                                        key={row.id || row.GroceriesFundAmount || row.GroceriesFundName}
                                    >
                                        <TableCell align="left">{row.GroceriesFundName}</TableCell>
                                        <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                        <TableCell align="center">{currencyFormat(row.GroceriesFundAmount)}</TableCell>
                                        <Button style={{marginTop: 9}} variant="contained"
                                                onClick={() => deleteGroceryExpenseFund(row)}>
                                            Delete
                                        </Button>
                                    </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="2">
                    <Typography textAlign="center">
                        Total amount Bri has spent <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(-briGroceryAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createBriExpense}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="BriGroceryExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="BriGroceriesExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groceryBriExpense.map((row) => (<TableRow
                                        key={row.id || row.BriGroceriesExpenseAmount || row.BriGroceryExpenseName}
                                    >
                                        <TableCell align="left">{row.BriGroceryExpenseName}</TableCell>
                                        <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                        <TableCell align="center">{currencyFormat(row.BriGroceriesExpenseAmount)}</TableCell>
                                        <Button style={{marginTop: 9}} variant="contained"
                                                onClick={() => deleteBriExpense(row)}>
                                            Delete
                                        </Button>
                                    </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="3">
                    <Typography textAlign="center">
                        Total amount Justin has spent <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(-justinGroceryAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createJustinExpense}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="JustinGroceriesExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="JustinGroceriesExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groceryJustinExpense.map((row) => (<TableRow
                                        key={row.id || row.JustinGroceriesExpenseAmount || row.JustinGroceriesExpenseName}
                                    >
                                        <TableCell align="left">{row.JustinGroceriesExpenseName}</TableCell>
                                        <TableCell align="center">{convertDate(row.createdAt)}</TableCell>
                                        <TableCell align="center">{currencyFormat(row.JustinGroceriesExpenseAmount)}</TableCell>
                                        <Button style={{marginTop: 9}} variant="contained"
                                                onClick={() => deleteJustinExpense(row)}>Delete</Button>
                                    </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br></br></div>
                <Typography textAlign="center">Amount to carry over at the end<br/> of the month is
                    <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}> {currencyFormat(amountToCarryOver)}</Typography>
                </Typography>
                <div><br></br></div>
                <Typography textAlign="center">**Monthly budget for Groceries is $950</Typography>
            </TabContext>
        </Box>
    );
}