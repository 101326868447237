import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {API} from "aws-amplify";
import {listBriGasExpenses, listGasFunds, listJustinGasExpenses} from "../../graphql/queries";
import {
    createBriGasExpense, createGasFund,
    createJustinGasExpense,
    deleteBriGasExpense, deleteGasFund,
    deleteJustinGasExpense
} from "../../graphql/mutations";
import {Flex, View} from "@aws-amplify/ui-react";
import {Button, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
    calculateBriGasTotal,
    calculateGasFundTotal,
    calculateJustinGasTotal,
    convertDate,
    currencyFormat
} from "../calculationFunctions";

let justinGasAmount = 0;
let briGasAmount = 0;
let gasFundAmount = 0;
let gasAmount = 0;

export default function GasTable() {
    const [gasBriExpense, setGasBriExpenses] = useState([]);
    const [gasJustinExpense, setGasJustinExpenses] = useState([]);
    const [gasFund, setGasFund] = useState([]);
    const [value, setValue] = React.useState('1');

    useEffect(() => {
        fetchTotalAmountofGas()
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalAmountofGas() {
        let briApiData = await API.graphql({query: listBriGasExpenses});
        let amountsFromBriAPI = briApiData.data.listBriGasExpenses.items;
        let justinApiData = await API.graphql({query: listJustinGasExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinGasExpenses.items;
        let gasFundApiData = await API.graphql({query: listGasFunds});
        let amountsFromGasFundAPI = gasFundApiData.data.listGasFunds.items;
        setGasBriExpenses(amountsFromBriAPI);
        setGasFund(amountsFromGasFundAPI);
        setGasJustinExpenses(amountsFromJustinAPI);
        justinGasAmount = calculateJustinGasTotal(amountsFromJustinAPI);
        gasFundAmount = calculateGasFundTotal(amountsFromGasFundAPI);
        briGasAmount = calculateBriGasTotal(amountsFromBriAPI);
        gasAmount = 300 + justinGasAmount + briGasAmount + gasFundAmount;
    }

    async function createBriExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            BriGasExpenseName: form.get("BriGasExpenseName"),
            BriGasExpenseAmount: form.get("BriGasExpenseAmount"),

        };
        await API.graphql({
            query: createBriGasExpense,
            variables: {input: data},
        });
        await fetchTotalAmountofGas()
        event.target.reset();
    }

    async function createGasFundEntry(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            GasFundName: form.get("GasFundName"),
            GasFundAmount: form.get("GasFundAmount"),

        };
        await API.graphql({
            query: createGasFund,
            variables: {input: data},
        });
        await fetchTotalAmountofGas()
        event.target.reset();
    }

    async function createJustinExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            JustinGasExpenseName: form.get("JustinGasExpenseName"),
            JustinGasExpenseAmount: form.get("JustinGasExpenseAmount"),
        };
        await API.graphql({
            query: createJustinGasExpense,
            variables: {input: data},
        });
        await fetchTotalAmountofGas()
        event.target.reset();
    }

    async function deleteBriExpense({id}) {
        const newExpense = gasBriExpense.filter((expense) => expense.id !== id);
        setGasBriExpenses(newExpense);
        await API.graphql({
            query: deleteBriGasExpense,
            variables: {input: {id}},
        });
        await fetchTotalAmountofGas()
    }

    async function deleteGasFundEntry({id}) {
        const newExpense = gasFund.filter((expense) => expense.id !== id);
        setGasFund(newExpense);
        await API.graphql({
            query: deleteGasFund,
            variables: {input: {id}},
        });
        await fetchTotalAmountofGas()
    }

    async function deleteJustinExpense({id}) {
        const newExpense = gasJustinExpense.filter((expense) => expense.id !== id);
        setGasJustinExpenses(newExpense);
        await API.graphql({
            query: deleteJustinGasExpense,
            variables: {input: {id}},
        });
        await fetchTotalAmountofGas()
    }

    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Gas Fund" value="1"/>
                        <Tab label="Bri" value="2"/>
                        <Tab label="Justin" value="3"/>
                    </TabList>
                </Box>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="1">
                    <Typography textAlign="center">Total Gas Fund<br/> for the month is
                        <Typography sx={{
                            fontWeight: 'bold',
                            display: 'inline'
                        }}> {currencyFormat(gasAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createGasFundEntry}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="GasFundName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="GasFundAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {gasFund.map((row) => (
                                        <TableRow
                                            key={row.id || row.GasFundAmount || row.GasFundName}
                                        >
                                            <TableCell align="left">{row.GasFundName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.GasFundAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteGasFundEntry(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="2">
                    <Typography textAlign="center">
                        Total amount Bri has spent <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(-briGasAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createBriExpense}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="BriGasExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="BriGasExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {gasBriExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.BriGasExpenseAmount || row.BriGasExpenseName}
                                        >
                                            <TableCell align="left">{row.BriGasExpenseName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.BriGasExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteBriExpense(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="3">
                    <Typography textAlign="center">
                        Total amount Justin has spent <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(-justinGasAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createJustinExpense}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="JustinGasExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="JustinGasExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {gasJustinExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.JustinGasExpenseAmount || row.JustinGasExpenseName}
                                        >
                                            <TableCell align="left">{row.JustinGasExpenseName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.JustinGasExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteJustinExpense(row)}>Delete</Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br></br></div>
            </TabContext>
            <Typography textAlign="center">Amount to carry over at the end<br/> of the month is
                <Typography sx={{
                    fontWeight: 'bold',
                    display: 'inline'
                }}> {currencyFormat(gasAmount)}</Typography>
            </Typography>
            <div><br></br></div>
            <Typography textAlign="center">***Monthly budget for Gas is $300</Typography>
        </Box>
    );
}