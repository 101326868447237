import React, {useEffect, useState} from "react";
import "@aws-amplify/ui-react/styles.css";
import {API} from "aws-amplify";
import {
    listBriEntertainmentExpenses,
    listEntertainmentFunds,
    listJustinEntertainmentExpenses,
} from "../../graphql/queries";
import {AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from "@mui/material/transitions";
import Entertainment from "../../components/Entertainment/EntertainmentTable"
import {
    calculateBriEntertainmentTotal, calculateEmergencyFund,
    calculateJustinEntertainmentTotal,
    currencyFormat,
} from "../calculationFunctions";

let justinEntertainmentAmount = 0;
let briEntertainmentAmount = 0;
let entertainmentFundAmount = 0;
let entertainmentAmount = 0;

const EntertainmentDialog = () => {
    const [entertainmentOpen, setEntertainmentOpen] = React.useState(false);
    const [briEntertainmentExpenses, setBriEntertainmentExpenses] = useState([]);
    const [entertainmentFund, setEntertainmentFund] = useState([]);
    const [justinEntertainmentExpenses, setJustinEntertainmentExpenses] = useState([]);

    const handleClickEntertainmentOpen = () => {
        let name = setEntertainmentOpen(true);
    };

    const handleEntertainmentClose = () => {
        window.location.reload();
        setEntertainmentOpen(false);
    };

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    useEffect(() => {
        fetchTotalAmountofEntertainment()

    }, []);


    async function fetchTotalAmountofEntertainment() {
        let briApiData = await API.graphql({query: listBriEntertainmentExpenses});
        let amountsFromBriAPI = briApiData.data.listBriEntertainmentExpenses.items;
        let justinApiData = await API.graphql({query: listJustinEntertainmentExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinEntertainmentExpenses.items;
        let entertainmentFundApiData = await API.graphql({query: listEntertainmentFunds});
        let amountsFromEntertainmentFundAPI = entertainmentFundApiData.data.listEntertainmentFunds.items;
        setEntertainmentFund(amountsFromEntertainmentFundAPI);
        setBriEntertainmentExpenses(amountsFromBriAPI);
        setJustinEntertainmentExpenses(amountsFromJustinAPI);
        entertainmentFundAmount = calculateEmergencyFund(amountsFromEntertainmentFundAPI);
        justinEntertainmentAmount = calculateJustinEntertainmentTotal(amountsFromJustinAPI);
        briEntertainmentAmount = calculateBriEntertainmentTotal(amountsFromBriAPI);
        entertainmentAmount = 450 + justinEntertainmentAmount + briEntertainmentAmount + entertainmentFundAmount;
    }

    return (
        <div>
            <Box
                textAlign='center'>
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined" onClick={handleClickEntertainmentOpen}>
                    <Typography sx={{fontSize: 14}}>Entertainment<Typography sx={{fontSize: 14}}>{currencyFormat(entertainmentAmount)} Left</Typography> </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={entertainmentOpen}
                onClose={handleEntertainmentClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleEntertainmentClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Entertainment
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Entertainment/>
            </Dialog>
        </div>
    );
};
export default EntertainmentDialog;


