import React, {useEffect, useState} from "react";
import "@aws-amplify/ui-react/styles.css";
import {API} from "aws-amplify";
import {listBriExpenses,} from "../../graphql/queries";
import {AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from "@mui/material/transitions";
import {calculateBriExpenseTotal, currencyFormat} from "../calculationFunctions";
import BriExpenseTable from "./BriExpenseTable";

let briExpenseAmount = 0;

const BriExpenseDialog = () => {
    const [briExpenseOpen, setBriExpenseOpen] = React.useState(false);
    const [briExpenseExpenses, setBriExpenses] = useState([]);
    const handleClickBriExpenseOpen = () => {
        let name = setBriExpenseOpen(true);
    };
    const handleBriExpenseClose = () => {
        window.location.reload();
        setBriExpenseOpen(false);
    };

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    useEffect(() => {
        fetchTotalAmountofBriExpense()

    }, []);


    async function fetchTotalAmountofBriExpense() {
        let briApiData = await API.graphql({query: listBriExpenses});
        let amountsFromBriAPI = briApiData.data.listBriExpenses.items;
        setBriExpenses(amountsFromBriAPI);
        briExpenseAmount = 125 + calculateBriExpenseTotal(amountsFromBriAPI);

    }

    return (
        <div>
            <Box
                textAlign='center'>
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined" onClick={handleClickBriExpenseOpen}>
                    Bri has {currencyFormat(briExpenseAmount)} Left
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={briExpenseOpen}
                onClose={handleBriExpenseClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleBriExpenseClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Bri's Expenses
                        </Typography>
                    </Toolbar>
                </AppBar>
                <BriExpenseTable/>
            </Dialog>
        </div>
    );
};
export default BriExpenseDialog;


