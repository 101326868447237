import React from "react";
import {currencyFormat} from "../calculationFunctions";

const monthlyBudget = 225;

const Circle = ({colour, pct}) => {
    const r = 70;
    const circ = 2 * Math.PI * r;
    const strokePct = ((monthlyBudget - pct) * circ) / monthlyBudget;
    return (
        <circle
            r={r}
            cx={100}
            cy={100}
            fill="#19173D"
            stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
            strokeWidth={"2rem"}
            strokeDasharray={circ}
            strokeDashoffset={pct ? strokePct : 0}
            strokeLinecap="round"
        ></circle>
    );
};

const Text = ({percentage}) => {

    const completed = monthlyBudget + percentage;

    return (
        <text
            fill="white"
            x="50%"
            y="50%"
            dominantBaseline="central"
            textAnchor="middle"
            fontSize={"1.5rem"}
        >
            {currencyFormat(completed)}
        </text>
    );
};

const JustinPie = ({percentage, colour}) => {
    const pct = percentage;
    return (
        <svg width={200} height={200}>
            <g transform={`rotate(-90 ${"100 100"})`}>
                <Circle colour="lightgrey"/>
                <Circle colour={colour} pct={pct}/>
            </g>
            <Text percentage={pct}/>
        </svg>
    );
};

export default JustinPie;

