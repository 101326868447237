import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {API} from "aws-amplify";
import {
    listBriEmergencyExpenses,
    listEmergencySavingsAmounts,
    listJustinEmergencyExpenses
} from "../../graphql/queries";
import {
    createBriEmergencyExpense,
    createEmergencySavingsAmount,
    createJustinEmergencyExpense,
    deleteBriEmergencyExpense,
    deleteEmergencySavingsAmount,
    deleteJustinEmergencyExpense,
} from "../../graphql/mutations";
import {Flex, View} from "@aws-amplify/ui-react";
import {Button, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import {
    calculateBriEmergencyExpenseTotal,
    calculateEmergencyExpenseTotal,
    calculateJustinEmergencyExpenseTotal,
    convertDate,
    currencyFormat
} from "../calculationFunctions";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";

let totalAmount = 0;
let totalJustinAmount = 0;
let totalBriAmount = 0;
let finalTotalAmount = 0;

export default function EmergencySavingsTable() {
    const [SavingsExpense, setSavingsExpenses] = useState([]);
    const [BriEmergencyExpense, setBriEmergencyExpenses] = useState([]);
    const [JustinEmergencyExpense, setJustinEmergencyExpenses] = useState([]);
    const [value, setValue] = React.useState('1');

    useEffect(() => {
        fetchEmergencySavingsItems()
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchEmergencySavingsItems() {
        const apiData = await API.graphql({query: listEmergencySavingsAmounts});
        const amountsFromAPI = apiData.data.listEmergencySavingsAmounts.items;
        const apiBriData = await API.graphql({query: listBriEmergencyExpenses});
        const amountsFromBriAPI = apiBriData.data.listBriEmergencyExpenses.items;
        const apiJustinData = await API.graphql({query: listJustinEmergencyExpenses});
        const amountsFromJustinAPI = apiJustinData.data.listJustinEmergencyExpenses.items;
        setSavingsExpenses(amountsFromAPI);
        setJustinEmergencyExpenses(amountsFromJustinAPI);
        setBriEmergencyExpenses(amountsFromBriAPI);
        totalAmount = calculateEmergencyExpenseTotal(amountsFromAPI);
        totalJustinAmount = calculateJustinEmergencyExpenseTotal(amountsFromJustinAPI);
        totalBriAmount = calculateBriEmergencyExpenseTotal(amountsFromBriAPI);
        finalTotalAmount = totalAmount + totalBriAmount + totalJustinAmount;
    }

    async function createEmergencySavingsEntry(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            EmergencySavingsName: form.get("EmergencySavingsName"),
            totalEmergencySavings: form.get("EmergencySavingsExpenseAmount"),

        };
        await API.graphql({
            query: createEmergencySavingsAmount,
            variables: {input: data},
        });
        await fetchEmergencySavingsItems()
        event.target.reset();
    }

    async function createBriEmergencySavingsEntry(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            BriEmergencyExpenseName: form.get("BriEmergencyExpenseName"),
            BriEmergencyExpenseAmount: form.get("BriEmergencyExpenseAmount"),

        };
        await API.graphql({
            query: createBriEmergencyExpense,
            variables: {input: data},
        });
        await fetchEmergencySavingsItems()
        event.target.reset();
    }

    async function createJustinEmergencySavingsEntry(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            JustinEmergencyExpenseName: form.get("JustinEmergencyExpenseName"),
            JustinEmergencyExpenseAmount: form.get("JustinEmergencyExpenseAmount"),

        };
        await API.graphql({
            query: createJustinEmergencyExpense,
            variables: {input: data},
        });
        await fetchEmergencySavingsItems()
        event.target.reset();
    }

    async function deleteSavingsExpense({id}) {
        const newExpense = SavingsExpense.filter((expense) => expense.id !== id);
        setSavingsExpenses(newExpense);
        await API.graphql({
            query: deleteEmergencySavingsAmount,
            variables: {input: {id}},
        });
        await fetchEmergencySavingsItems()
    }

    async function deleteBriSavingsExpense({id}) {
        const newExpense = BriEmergencyExpense.filter((expense) => expense.id !== id);
        setBriEmergencyExpenses(newExpense);
        await API.graphql({
            query: deleteBriEmergencyExpense,
            variables: {input: {id}},
        });
        await fetchEmergencySavingsItems()
    }

    async function deleteJustinSavingsExpense({id}) {
        const newExpense = JustinEmergencyExpense.filter((expense) => expense.id !== id);
        setBriEmergencyExpenses(newExpense);
        await API.graphql({
            query: deleteJustinEmergencyExpense,
            variables: {input: {id}},
        });
        await fetchEmergencySavingsItems()
    }

    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Emergency  Fund" value="1"/>
                        <Tab label="Bri" value="2"/>
                        <Tab label="Justin" value="3"/>
                    </TabList>
                </Box>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="1">
                    <Typography textAlign="center">
                        Total Savings <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(finalTotalAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createEmergencySavingsEntry}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="EmergencySavingsName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="EmergencySavingsExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {SavingsExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.totalEmergencySavings || row.EmergencySavingsName}
                                        >
                                            <TableCell align="left">{row.EmergencySavingsName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.totalEmergencySavings)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteSavingsExpense(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="2">
                    <Typography textAlign="center">
                        Total Savings <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(finalTotalAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createBriEmergencySavingsEntry}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="BriEmergencyExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="BriEmergencyExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {BriEmergencyExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.BriEmergencyExpenseAmount || row.BriEmergencyExpenseName}
                                        >
                                            <TableCell align="left">{row.BriEmergencyExpenseName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.BriEmergencyExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteBriSavingsExpense(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="3">
                    <Typography textAlign="center">
                        Total Savings <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(finalTotalAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createJustinEmergencySavingsEntry}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="JustinEmergencyExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="JustinEmergencyExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {JustinEmergencyExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.JustinEmergencyExpenseAmount || row.JustinEmergencyExpenseName}
                                        >
                                            <TableCell align="left">{row.JustinEmergencyExpenseName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.JustinEmergencyExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteJustinSavingsExpense(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br></br></div>
            </TabContext>
        </Box>
    );
}