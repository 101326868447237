import React, {useEffect, useState} from "react";
import "@aws-amplify/ui-react/styles.css";
import Pie from "../BriPie/BriPie";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {API} from "aws-amplify";
import {listBriExpenses} from "../../graphql/queries";
import {calculateBriExpenseTotal, currencyFormat} from "../calculationFunctions";

let briExpenseAmount = 0;

const BriPieDialog = () => {
    const [briExpenseExpenses, setBriExpenses] = useState([]);

    useEffect(() => {
        fetchTotalAmountofBriExpense()
    }, []);

    async function fetchTotalAmountofBriExpense() {
        let briApiData = await API.graphql({query: listBriExpenses});
        let amountsFromBriAPI = briApiData.data.listBriExpenses.items;
        setBriExpenses(amountsFromBriAPI);
        briExpenseAmount = calculateBriExpenseTotal(amountsFromBriAPI);
    }

    return (
        <div>
            <Typography
                minHeight="10vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{color: 'white', fontSize: "xx-large"}}>Running dollars spent
                {" " + currencyFormat(-briExpenseAmount)}</Typography>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="5vh"
            >
                <Pie mb={2} percentage={briExpenseAmount} colour={"#0DA6C2"}/>
            </Box>
        </div>
    );
};
export default BriPieDialog;