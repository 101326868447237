import React, {useEffect, useState} from "react";
import "@aws-amplify/ui-react/styles.css";
import Pie from "../HomePie/HomePie";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {API} from "aws-amplify";
import {
    listBriEntertainmentExpenses,
    listBriExpenses,
    listBriGasExpenses,
    listBriGroceryExpenses,
    listBriIanExpenses,
    listJustinEntertainmentExpenses,
    listJustinExpenses,
    listJustinGasExpenses,
    listJustinGroceryExpenses,
    listJustinIanExpenses
} from "../../graphql/queries";
import {
    calculateBriEntertainmentTotal,
    calculateBriExpenseTotal,
    calculateBriGasTotal,
    calculateBriGroceryTotal,
    calculateBriIanAllowanceTotal,
    calculateJustinEntertainmentTotal,
    calculateJustinExpenseTotal,
    calculateJustinGasTotal,
    calculateJustinGroceryTotal,
    calculateJustinIanAllowanceTotal,
    currencyFormat
} from "../calculationFunctions";

let justinExpenseAmount = 0;
let briExpenseAmount = 0;
let justinEntertainmentAmount = 0;
let briEntertainmentAmount = 0;
let entertainmentAmount = 0;
let justinGasAmount = 0;
let briGasAmount = 0;
let gasAmount = 0;
let justinGroceryAmount = 0;
let briGroceryAmount = 0;
let groceryAmount = 0;
let justinIanAllowanceAmount = 0;
let briIanAllowanceAmount = 0;
let ianAllowanceAmount = 0;



const HomePieDialog = () => {
    const [justinExpenseExpenses, setJustinExpenses] = useState([]);
    const [briExpenseExpenses, setBriExpenses] = useState([]);
    const [briExtrasExpenses, setBriExtrasExpenses] = useState([]);
    const [justinExtrasExpenses, setJustinExtrasExpenses] = useState([]);
    const [briEntertainmentExpenses, setBriEntertainmentExpenses] = useState([]);
    const [justinEntertainmentExpenses, setJustinEntertainmentExpenses] = useState([]);
    const [briGasExpenses, setBriGasExpenses] = useState([]);
    const [justinGasExpenses, setJustinGasExpenses] = useState([]);
    const [briGroceryExpense, setBriGroceryExpenses] = useState([]);
    const [justinGroceryExpense, setJustinGroceryExpenses] = useState([]);
    const [briIanAllowanceExpenses, setBriIanAllowanceExpenses] = useState([]);
    const [justinIanAllowanceExpenses, setJustinIanAllowanceExpenses] = useState([]);

    useEffect(() => {
        fetchTotalAmountofJustinExpense()
        fetchTotalAmountofBriExpense()
        fetchTotalAmountofEntertainment()
        fetchTotalAmountofGas()
        fetchTotalAmountofGroceries()
        fetchTotalAmountofIanAllowance()

    }, []);


    async function fetchTotalAmountofJustinExpense() {
        let justinApiData = await API.graphql({query: listJustinExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinExpenses.items;
        setJustinExpenses(amountsFromJustinAPI);
        justinExpenseAmount = calculateJustinExpenseTotal(amountsFromJustinAPI);
    }

    async function fetchTotalAmountofBriExpense() {
        let briApiData = await API.graphql({query: listBriExpenses});
        let amountsFromBriAPI = briApiData.data.listBriExpenses.items;
        setBriExpenses(amountsFromBriAPI);
        briExpenseAmount = calculateBriExpenseTotal(amountsFromBriAPI);
    }

    async function fetchTotalAmountofEntertainment() {
        let briApiData = await API.graphql({query: listBriEntertainmentExpenses});
        let amountsFromBriAPI = briApiData.data.listBriEntertainmentExpenses.items;
        let justinApiData = await API.graphql({query: listJustinEntertainmentExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinEntertainmentExpenses.items;
        setBriEntertainmentExpenses(amountsFromBriAPI);
        setJustinEntertainmentExpenses(amountsFromJustinAPI);
        justinEntertainmentAmount = calculateJustinEntertainmentTotal(amountsFromJustinAPI);
        briEntertainmentAmount = calculateBriEntertainmentTotal(amountsFromBriAPI);
        entertainmentAmount = justinEntertainmentAmount + briEntertainmentAmount;
    }

    async function fetchTotalAmountofGas() {
        let briApiData = await API.graphql({query: listBriGasExpenses});
        let amountsFromBriAPI = briApiData.data.listBriGasExpenses.items;
        let justinApiData = await API.graphql({query: listJustinGasExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinGasExpenses.items;
        setBriGasExpenses(amountsFromBriAPI);
        setJustinGasExpenses(amountsFromJustinAPI);
        justinGasAmount = calculateJustinGasTotal(amountsFromJustinAPI);
        briGasAmount = calculateBriGasTotal(amountsFromBriAPI);
        gasAmount = justinGasAmount + briGasAmount;
    }

    async function fetchTotalAmountofGroceries() {
        let briApiData = await API.graphql({query: listBriGroceryExpenses});
        let amountsFromBriAPI = briApiData.data.listBriGroceryExpenses.items;
        let justinApiData = await API.graphql({query: listJustinGroceryExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinGroceryExpenses.items;
        setBriGroceryExpenses(amountsFromBriAPI);
        setJustinGroceryExpenses(amountsFromJustinAPI);
        justinGroceryAmount = calculateJustinGroceryTotal(amountsFromJustinAPI);
        briGroceryAmount = calculateBriGroceryTotal(amountsFromBriAPI);
        groceryAmount = justinGroceryAmount + briGroceryAmount;
    }

    async function fetchTotalAmountofIanAllowance() {
        let briApiData = await API.graphql({query: listBriIanExpenses});
        let amountsFromBriAPI = briApiData.data.listBriIanExpenses.items;
        let justinApiData = await API.graphql({query: listJustinIanExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinIanExpenses.items;
        setBriIanAllowanceExpenses(amountsFromBriAPI);
        setJustinIanAllowanceExpenses(amountsFromJustinAPI);
        justinIanAllowanceAmount = calculateJustinIanAllowanceTotal(amountsFromJustinAPI);
        briIanAllowanceAmount = calculateBriIanAllowanceTotal(amountsFromBriAPI);
        ianAllowanceAmount = justinIanAllowanceAmount + briIanAllowanceAmount;
    }

    let amount = entertainmentAmount +
        gasAmount +
        groceryAmount +
        ianAllowanceAmount +
        justinExpenseAmount +
        briExpenseAmount;
    return (
        <div>
            <Typography
                textAlign="center"
                minHeight="10vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{color: 'white', fontSize: "xx-large"}}>Running dollars spent
                { " " + currencyFormat(-amount)}</Typography>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="5vh"
            >
                <Pie mb={2} percentage={amount} colour={"#0DA6C2"}/>
            </Box>
        </div>
    );
};
export default HomePieDialog;