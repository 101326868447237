import React, {useEffect, useState} from "react";
import "@aws-amplify/ui-react/styles.css";
import {API} from "aws-amplify";
import {listJustinExpenses,} from "../../graphql/queries";
import {AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from "@mui/material/transitions";
import {calculateJustinExpenseTotal, currencyFormat} from "../calculationFunctions";
import JustinExpenseTable from "./JustinExpenseTable";

let justinExpenseAmount = 0;


const JustinExpenseDialog = () => {
    const [justinExpenseOpen, setJustinExpenseOpen] = React.useState(false);
    const [justinExpenseExpenses, setJustinExpenses] = useState([]);
    const handleClickJustinExpenseOpen = () => {
        let name = setJustinExpenseOpen(true);
    };
    const handleJustinExpenseClose = () => {
        window.location.reload();
        setJustinExpenseOpen(false);
    };

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    useEffect(() => {
        fetchTotalAmountofJustinExpense()

    }, []);


    async function fetchTotalAmountofJustinExpense() {
        let justinApiData = await API.graphql({query: listJustinExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinExpenses.items;
        setJustinExpenses(amountsFromJustinAPI);
        justinExpenseAmount = 225 + calculateJustinExpenseTotal(amountsFromJustinAPI);
    }

    return (
        <div>
            <Box
                textAlign='center'>
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined" onClick={handleClickJustinExpenseOpen}>
                    Justin has {currencyFormat(justinExpenseAmount)} Left
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={justinExpenseOpen}
                onClose={handleJustinExpenseClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleJustinExpenseClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Justin's Expenses
                        </Typography>
                    </Toolbar>
                </AppBar>
                <JustinExpenseTable/>
            </Dialog>
        </div>
    );
};
export default JustinExpenseDialog;


