import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {API} from "aws-amplify";
import {listBriExpenses} from "../../graphql/queries";
import {
    createBriExpense,
    deleteBriExpense,
} from "../../graphql/mutations";
import {Flex, View} from "@aws-amplify/ui-react";
import {Button, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {calculateBriExpenseTotal, convertDate, currencyFormat} from "../calculationFunctions";

let briExpenseAmount = 0;
let amountToCarryOver = 0;

export default function BriExpenseTable() {
    const [BriExpense, setBriExpenses] = useState([]);
    const [value, setValue] = React.useState('1');

    useEffect(() => {
        fetchTotalAmountofBriExpense()
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalAmountofBriExpense() {
        let briApiData = await API.graphql({query: listBriExpenses});
        let amountsFromBriAPI = briApiData.data.listBriExpenses.items;
        setBriExpenses(amountsFromBriAPI);
        briExpenseAmount = calculateBriExpenseTotal(amountsFromBriAPI);
        amountToCarryOver = 125 + briExpenseAmount;
    }

    async function createNewBriExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            BriExpenseName: form.get("BriExpenseName"),
            BriExpenseAmount: form.get("BriExpenseAmount"),

        };
        await API.graphql({
            query: createBriExpense,
            variables: {input: data},
        });
        await fetchTotalAmountofBriExpense()
        event.target.reset();
    }

    async function deleteOldBriExpense({id}) {
        const newExpense = BriExpense.filter((expense) => expense.id !== id);
        setBriExpenses(newExpense);
        await API.graphql({
            query: deleteBriExpense,
            variables: {input: {id}},
        });
        await fetchTotalAmountofBriExpense()
    }

    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Bri" value="1"/>
                    </TabList>
                </Box>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="1">
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createNewBriExpense}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="BriExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="BriExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {BriExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.BriExpenseAmount || row.BriExpenseName}
                                        >
                                            <TableCell align="left">{row.BriExpenseName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.BriExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteOldBriExpense(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br></br></div>
                <Typography textAlign="center">Amount to carry over at the end<br/> of the month is
                    <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}> {currencyFormat(amountToCarryOver)}</Typography>
                </Typography>
                <div><br></br></div>
            </TabContext>
            <Typography textAlign="center">***Monthly budget for Bri's Money is $125</Typography>
        </Box>


    );
}