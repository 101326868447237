import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {API} from "aws-amplify";
import {
    listBriEntertainmentExpenses,
    listEntertainmentFunds,
    listJustinEntertainmentExpenses
} from "../../graphql/queries";
import {
    createBriEntertainmentExpense, createEntertainmentFund,
    createJustinEntertainmentExpense,
    deleteBriEntertainmentExpense, deleteEntertainmentFund,
    deleteJustinEntertainmentExpense
} from "../../graphql/mutations";
import {Flex, View} from "@aws-amplify/ui-react";
import {Button, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
    calculateBriEntertainmentTotal, calculateEmergencyFund,
    calculateJustinEntertainmentTotal, convertDate,
    currencyFormat
} from "../calculationFunctions";

let justinEntertainmentAmount = 0;
let briEntertainmentAmount = 0;
let entertainmentFundAmount = 0;
let entertainmentAmount = 0;

export default function EntertainmentTable() {
    const [entertainmentBriExpense, setEntertainmentBriExpenses] = useState([]);
    const [entertainmentJustinExpense, setEntertainmentJustinExpenses] = useState([]);
    const [entertainmentFund, setEntertainmentFund] = useState([]);
    const [value, setValue] = React.useState('1');

    useEffect(() => {
        fetchTotalAmountofEntertainment()
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalAmountofEntertainment() {
        let briApiData = await API.graphql({query: listBriEntertainmentExpenses});
        let amountsFromBriAPI = briApiData.data.listBriEntertainmentExpenses.items;
        let justinApiData = await API.graphql({query: listJustinEntertainmentExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinEntertainmentExpenses.items;
        let entertainmentFundApiData = await API.graphql({query: listEntertainmentFunds});
        let amountsFromEntertainmentFundAPI = entertainmentFundApiData.data.listEntertainmentFunds.items;
        setEntertainmentBriExpenses(amountsFromBriAPI);
        setEntertainmentJustinExpenses(amountsFromJustinAPI);
        setEntertainmentFund(amountsFromEntertainmentFundAPI);
        justinEntertainmentAmount = calculateJustinEntertainmentTotal(amountsFromJustinAPI);
        entertainmentFundAmount = calculateEmergencyFund(amountsFromEntertainmentFundAPI);
        briEntertainmentAmount = calculateBriEntertainmentTotal(amountsFromBriAPI);
        entertainmentAmount = 450 + justinEntertainmentAmount + briEntertainmentAmount + entertainmentFundAmount;
    }

    async function createBriExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            BriEntertainmentExpenseName: form.get("BriEntertainmentExpenseName"),
            BriEntertainmentExpenseAmount: form.get("BriEntertainmentExpenseAmount"),

        };
        await API.graphql({
            query: createBriEntertainmentExpense,
            variables: {input: data},
        });
        await fetchTotalAmountofEntertainment()
        event.target.reset();
    }

    async function createFund(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            EntertainmentFundName: form.get("EntertainmentFundName"),
            EntertainmentFundAmount: form.get("EntertainmentFundAmount"),

        };
        await API.graphql({
            query: createEntertainmentFund,
            variables: {input: data},
        });
        await fetchTotalAmountofEntertainment()
        event.target.reset();
    }

    async function createJustinExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            JustinEntertainmentExpenseName: form.get("JustinEntertainmentExpenseName"),
            JustinEntertainmentExpenseAmount: form.get("JustinEntertainmentExpenseAmount"),
        };
        await API.graphql({
            query: createJustinEntertainmentExpense,
            variables: {input: data},
        });
        await fetchTotalAmountofEntertainment()
        event.target.reset();
    }

    async function deleteFund({id}) {
        const newExpense = entertainmentFund.filter((expense) => expense.id !== id);
        setEntertainmentFund(newExpense);
        await API.graphql({
            query: deleteEntertainmentFund,
            variables: {input: {id}},
        });
        await fetchTotalAmountofEntertainment()
    }

    async function deleteBriExpense({id}) {
        const newExpense = entertainmentBriExpense.filter((expense) => expense.id !== id);
        setEntertainmentBriExpenses(newExpense);
        await API.graphql({
            query: deleteBriEntertainmentExpense,
            variables: {input: {id}},
        });
        await fetchTotalAmountofEntertainment()
    }

    async function deleteJustinExpense({id}) {
        const newExpense = entertainmentJustinExpense.filter((expense) => expense.id !== id);
        setEntertainmentJustinExpenses(newExpense);
        await API.graphql({
            query: deleteJustinEntertainmentExpense,
            variables: {input: {id}},
        });
        await fetchTotalAmountofEntertainment()
    }

    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Entertainment Fund" value="1"/>
                        <Tab label="Bri" value="2"/>
                        <Tab label="Justin" value="3"/>
                    </TabList>
                </Box>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="1">
                    <Typography textAlign="center">Total Entertainment Fund<br/> for the month is
                        <Typography sx={{
                            fontWeight: 'bold',
                            display: 'inline'
                        }}> {currencyFormat(entertainmentAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createFund}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="EntertainmentFundName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="EntertainmentFundAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {entertainmentFund.map((row) => (
                                        <TableRow
                                            key={row.id || row.EntertainmentFundAmount || row.EntertainmentFundName}
                                        >
                                            <TableCell align="left">{row.EntertainmentFundName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.EntertainmentFundAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteFund(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="2">
                    <Typography textAlign="center">
                        Total amount Bri has spent <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(-briEntertainmentAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createBriExpense}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="BriEntertainmentExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="BriEntertainmentExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {entertainmentBriExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.BriEntertainmentExpenseAmount || row.BriEntertainmentExpenseName}
                                        >
                                            <TableCell align="left">{row.BriEntertainmentExpenseName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.BriEntertainmentExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteBriExpense(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="3">
                    <Typography textAlign="center">
                        Total amount Justin has spent <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(-justinEntertainmentAmount)}</Typography>
                    </Typography>

                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createJustinExpense}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="JustinEntertainmentExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="JustinEntertainmentExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {entertainmentJustinExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.JustinEntertainmentExpenseAmount || row.JustinEntertainmentExpenseName}
                                        >
                                            <TableCell align="left">{row.JustinEntertainmentExpenseName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.JustinEntertainmentExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteJustinExpense(row)}>Delete</Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br></br></div>
            </TabContext>
            <Typography textAlign="center">Amount to carry over at the end<br/> of the month is
                <Typography sx={{
                    fontWeight: 'bold',
                    display: 'inline'
                }}> {currencyFormat(entertainmentAmount)}</Typography>
            </Typography>
            <div><br></br></div>
            <Typography textAlign="center">***Monthly budget for Entertainment is $450</Typography>
        </Box>
    );
}