import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {API} from "aws-amplify";
import {Flex, View} from "@aws-amplify/ui-react";
import {Button, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import {
    calculateBriTravelExpenseTotal, calculateJustinTravelExpenseTotal,
    calculateTravelExpenseTotal,
    convertDate,
    currencyFormat
} from "../calculationFunctions";
import {
    createBriTravelExpense,
    createTravelAmount,
    deleteTravelAmount,
    deleteBriTravelExpense, createJustinTravelExpense, deleteJustinTravelExpense
} from "../../graphql/mutations";
import {listBriTravelExpenses, listJustinTravelExpenses, listTravelAmounts} from "../../graphql/queries";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";

let totalAmount = 0;
let totalBriAmount = 0;
let totalJustinAmount = 0;
let finalTotal = 0


export default function TravelTable() {
    const [TravelExpense, setTravelExpenses] = useState([]);
    const [BriTravelExpense, setBriTravelExpenses] = useState([]);
    const [JustinTravelExpense, setJustinTravelExpenses] = useState([]);
    const [value, setValue] = React.useState('1');

    useEffect(() => {
        fetchTotalTravelFund()
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalTravelFund() {
        const apiData = await API.graphql({query: listTravelAmounts});
        const amountsFromAPI = apiData.data.listTravelAmounts.items;
        const apiBriData = await API.graphql({query: listBriTravelExpenses});
        const amountsFromBriAPI = apiBriData.data.listBriTravelExpenses.items;
        const apiJustinData = await API.graphql({query: listJustinTravelExpenses});
        const amountsFromJustinAPI = apiJustinData.data.listJustinTravelExpenses.items;
        setJustinTravelExpenses(amountsFromJustinAPI);
        setBriTravelExpenses(amountsFromBriAPI);
        setTravelExpenses(amountsFromAPI);
        totalJustinAmount = calculateJustinTravelExpenseTotal(amountsFromJustinAPI);
        totalBriAmount = calculateBriTravelExpenseTotal(amountsFromBriAPI);
        totalAmount = calculateTravelExpenseTotal(amountsFromAPI);
        finalTotal = totalAmount + totalBriAmount + totalJustinAmount;
    }

    async function createTravelEntry(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            TravelName: form.get("TravelName"),
            TravelAmount: form.get("TravelAmount"),

        };
        await API.graphql({
            query: createTravelAmount,
            variables: {input: data},
        });
        fetchTotalTravelFund()
        event.target.reset();
    }

    async function createBriTravelEntry(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            BriTravelExpenseName: form.get("BriTravelExpenseName"),
            BriTravelExpenseAmount: form.get("BriTravelExpenseAmount"),

        };
        await API.graphql({
            query: createBriTravelExpense,
            variables: {input: data},
        });
        fetchTotalTravelFund()
        event.target.reset();
    }

    async function createJustinTravelEntry(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            JustinTravelExpenseName: form.get("JustinTravelExpenseName"),
            JustinTravelExpenseAmount: form.get("JustinTravelExpenseAmount"),

        };
        await API.graphql({
            query: createJustinTravelExpense,
            variables: {input: data},
        });
        fetchTotalTravelFund()
        event.target.reset();
    }

    async function deleteTravelExpense({id}) {
        const newExpense = TravelExpense.filter((expense) => expense.id !== id);
        setTravelExpenses(newExpense);
        await API.graphql({
            query: deleteTravelAmount,
            variables: {input: {id}},
        });
        fetchTotalTravelFund()
    }

    async function deleteBriTravelExpenses({id}) {
        const newExpense = BriTravelExpense.filter((expense) => expense.id !== id);
        setBriTravelExpenses(newExpense);
        await API.graphql({
            query: deleteBriTravelExpense,
            variables: {input: {id}},
        });
        fetchTotalTravelFund()
    }

    async function deleteJustinTravelExpenses({id}) {
        const newExpense = JustinTravelExpense.filter((expense) => expense.id !== id);
        setJustinTravelExpenses(newExpense);
        await API.graphql({
            query: deleteJustinTravelExpense,
            variables: {input: {id}},
        });
        fetchTotalTravelFund()
    }

    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Travel Fund" value="1"/>
                        <Tab label="Bri" value="2"/>
                        <Tab label="Justin" value="3"/>
                    </TabList>
                </Box>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="1">
                    <Typography textAlign="center">
                        Total Travel Fund <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(finalTotal)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createTravelEntry}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="TravelName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="TravelAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {TravelExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.TravelAmount || row.TravelName}
                                        >
                                            <TableCell align="left">{row.TravelName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.TravelAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteTravelExpense(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="2">
                    <Typography textAlign="center">
                        Total Travel Fund <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(finalTotal)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createBriTravelEntry}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="BriTravelExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="BriTravelExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {BriTravelExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.BriTravelExpenseAmount || row.BriTravelExpenseName}
                                        >
                                            <TableCell align="left">{row.BriTravelExpenseName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.BriTravelExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteBriTravelExpenses(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="3">
                    <Typography textAlign="center">
                        Total Travel Fund <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(finalTotal)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createJustinTravelEntry}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="JustinTravelExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="JustinTravelExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {JustinTravelExpense.map((row) => (
                                        <TableRow
                                            key={row.id || row.JustinTravelExpenseAmount || row.JustinTravelExpenseName}
                                        >
                                            <TableCell align="left">{row.JustinTravelExpenseName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.JustinTravelExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteJustinTravelExpenses(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br></br></div>
            </TabContext>
        </Box>
    );
}