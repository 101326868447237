import React, {useEffect, useState} from "react";
import "@aws-amplify/ui-react/styles.css";
import {API} from "aws-amplify";
import {listBriExtrasExpenses, listExtrasFunds, listJustinExtrasExpenses,} from "../../graphql/queries";
import {AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from "@mui/material/transitions";
import Extras from "../../components/Extras/ExtrasTable"
import {
    calculateBriExtrasTotal,
    calculateExtraFundTotal,
    calculateJustinExtrasTotal,
    currencyFormat,
} from "../calculationFunctions";

let justinExtrasAmount = 0;
let briExtrasAmount = 0;
let extraFundAmount = 0;
let extrasAmount = 0;

const ExtrasDialog = () => {
    const [extrasOpen, setExtrasOpen] = React.useState(false);
    const [close, setClose] = React.useState(true);
    const [extrasFund, setExtrasFund] = useState([]);
    const [briExtrasExpenses, setBriExtrasExpenses] = useState([]);
    const [justinExtrasExpenses, setJustinExtrasExpenses] = useState([]);

    const handleClickExtrasOpen = () => {
        let name = setExtrasOpen(true);
    };

    const handleExtrasClose = () => {
        window.location.reload();
        setExtrasOpen(false);
    };

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    useEffect(() => {
        fetchTotalAmountofExtras()

    }, []);


    async function fetchTotalAmountofExtras() {
        let briApiData = await API.graphql({query: listBriExtrasExpenses});
        let amountsFromBriAPI = briApiData.data.listBriExtrasExpenses.items;
        let justinApiData = await API.graphql({query: listJustinExtrasExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinExtrasExpenses.items;
        let extraFundApiData = await API.graphql({query: listExtrasFunds});
        let amountsFromExtraFundAPI = extraFundApiData.data.listExtrasFunds.items;
        setExtrasFund(amountsFromExtraFundAPI);
        setBriExtrasExpenses(amountsFromBriAPI);
        setJustinExtrasExpenses(amountsFromJustinAPI);
        justinExtrasAmount = calculateJustinExtrasTotal(amountsFromJustinAPI);
        briExtrasAmount = calculateBriExtrasTotal(amountsFromBriAPI);
        extraFundAmount = calculateExtraFundTotal(amountsFromExtraFundAPI);
        extrasAmount = 650 + justinExtrasAmount + briExtrasAmount + extraFundAmount;
    }

    return (
        <div>
            <Box
                textAlign='center'>
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined" onClick={handleClickExtrasOpen}>
                    <Typography sx={{fontSize: 14}}>Extra's Fund<Typography sx={{fontSize: 14}}>{currencyFormat(extrasAmount)} Total</Typography> </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={extrasOpen}
                onClose={handleExtrasClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleExtrasClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Extra's
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Extras/>
            </Dialog>
        </div>
    );
};
export default ExtrasDialog;


