import React, {useEffect, useState} from "react";
import "@aws-amplify/ui-react/styles.css";
import {API} from "aws-amplify";
import {listBriGroceryExpenses, listGroceryFunds, listJustinGroceryExpenses} from "../../graphql/queries";
import {AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from "@mui/material/transitions";
import GroceryTable from "../../components/Groceries/GroceriesTable";
import {
    calculateBriGroceryTotal,
    calculateGroceryFundTotal,
    calculateJustinGroceryTotal,
    currencyFormat
} from "../calculationFunctions";

let justinGroceryAmount = 0;
let briGroceryAmount = 0;
let groceryAmount = 0;
let groceryRunningFund = 0;

const GroceriesDialog = () => {
    const [groceryOpen, setGroceryOpen] = React.useState(false);
    const [close, setClose] = React.useState(true);
    const [groceryFund, setGroceryFund] = useState([]);
    const [briGroceryExpense, setBriGroceryExpenses] = useState([]);
    const [justinGroceryExpense, setJustinGroceryExpenses] = useState([]);
    const handleClickGroceryOpen = () => {
        let name = setGroceryOpen(true);
    };
    const handleGroceryClose = () => {
        window.location.reload();
        setGroceryOpen(false);
    };
    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    useEffect(() => {
        fetchTotalAmountofGroceries();

    }, []);

    async function fetchTotalAmountofGroceries() {
        let briApiData = await API.graphql({query: listBriGroceryExpenses});
        let amountsFromBriAPI = briApiData.data.listBriGroceryExpenses.items;
        let justinApiData = await API.graphql({query: listJustinGroceryExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinGroceryExpenses.items;
        let fundApiData = await API.graphql({query: listGroceryFunds});
        let amountsFromFundAPI = fundApiData.data.listGroceryFunds.items;
        setGroceryFund(amountsFromFundAPI);
        setBriGroceryExpenses(amountsFromBriAPI);
        setJustinGroceryExpenses(amountsFromJustinAPI);
        groceryRunningFund = calculateGroceryFundTotal(amountsFromFundAPI);
        justinGroceryAmount = calculateJustinGroceryTotal(amountsFromJustinAPI);
        briGroceryAmount = calculateBriGroceryTotal(amountsFromBriAPI);
        groceryAmount = 950 + justinGroceryAmount + briGroceryAmount + groceryRunningFund;
    }

    return (
        <div>
            <Box
                textAlign='center'>
                <Button
                    sx={{
                        color: 'white',
                        bgcolor: '#4417c6',
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 2,
                        mt: 4,
                        minWidth: 300,
                    }}
                    variant="outlined" onClick={handleClickGroceryOpen}>
                    <Typography sx={{fontSize: 14}}>Groceries<Typography sx={{fontSize: 14}}>{currencyFormat(groceryAmount)} Left</Typography> </Typography>
                </Button>
            </Box>
            <Dialog
                fullScreen
                open={groceryOpen}
                onClose={handleGroceryClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleGroceryClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Groceries
                        </Typography>
                    </Toolbar>
                </AppBar>
                <GroceryTable/>
            </Dialog>
        </div>
    );
};
export default GroceriesDialog;


