import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {API} from "aws-amplify";
import {
    listBriEmergencyExpenses,
    listBriEntertainmentExpenses,
    listBriExtrasExpenses,
    listBriGasExpenses,
    listBriGroceryExpenses,
    listBriIanExpenses,
    listBriTravelExpenses,
    listJustinEmergencyExpenses,
    listJustinEntertainmentExpenses,
    listJustinExpenses,
    listJustinExtrasExpenses,
    listJustinGasExpenses,
    listJustinGroceryExpenses,
    listJustinIanExpenses, listJustinTravelExpenses
} from "../../graphql/queries";
import {createJustinExpense, deleteJustinExpense,} from "../../graphql/mutations";
import {Flex, View} from "@aws-amplify/ui-react";
import {Button, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
    calculateBriEmergencyExpenseTotal,
    calculateBriEntertainmentTotal,
    calculateBriExtrasTotal,
    calculateBriGasTotal,
    calculateBriGroceryTotal,
    calculateBriIanAllowanceTotal,
    calculateBriTravelExpenseTotal,
    calculateJustinEmergencyExpenseTotal,
    calculateJustinEntertainmentTotal,
    calculateJustinExpenseTotal,
    calculateJustinExtrasTotal,
    calculateJustinGasTotal,
    calculateJustinGroceryTotal,
    calculateJustinIanAllowanceTotal, calculateJustinTravelExpenseTotal,
    convertDate,
    currencyFormat
} from "../calculationFunctions";

let justinExpenseAmount = 0;
let amountToCarryOver = 0;
let briGroceryAmount = 0;
let briEntertainmentAmount = 0;
let briGasAmount = 0;
let briExtrasAmount = 0;
let briIanAllowanceAmount = 0;
let totalBriTravelAmount = 0;
let totalBriEmergencyAmount = 0;
let totalJustinSpent = 0;
let justinEntertainmentAmount = 0;
let totalJustinEmergencyAmount = 0;
let justinExtrasAmount = 0;
let justinGasAmount = 0;
let justinGroceryAmount = 0;
let justinIanAllowanceAmount = 0;
let justinTravelAmount = 0;

export default function JustinExpenseTable() {
    const [JustinExpense, setJustinExpenses] = useState([]);
    const [value, setValue] = React.useState('1');
    const [entertainmentJustinExpense, setEntertainmentJustinExpenses] = useState([]);
    const [JustinEmergencyExpense, setJustinEmergencyExpenses] = useState([]);
    const [extrasJustinExpense, setExtrasJustinExpenses] = useState([]);
    const [gasJustinExpense, setGasJustinExpenses] = useState([]);
    const [groceryJustinExpense, setGroceryJustinExpenses] = useState([]);
    const [ianAllowanceJustinExpense, setIanAllowanceJustinExpenses] = useState([]);
    const [JustinTravelExpense, setJustinTravelExpenses] = useState([]);
    const [groceryBriExpense, setGroceryBriExpenses] = useState([]);
    const [BriEmergencyExpense, setBriEmergencyExpenses] = useState([]);
    const [BriTravelExpense, setBriTravelExpenses] = useState([]);
    const [entertainmentBriExpense, setEntertainmentBriExpenses] = useState([]);
    const [gasBriExpense, setGasBriExpenses] = useState([]);
    const [extrasBriExpense, setExtrasBriExpenses] = useState([]);
    const [ianAllowanceBriExpense, setIanAllowanceBriExpenses] = useState([]);

    useEffect(() => {
        fetchTotalAmountofJustinExpense()
        fetchTotalAmountofBriItems()
        fetchTotalAddedJustinSpending()

    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function fetchTotalAmountofJustinExpense() {
        let justinApiData = await API.graphql({query: listJustinExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinExpenses.items;
        setJustinExpenses(amountsFromJustinAPI);
        justinExpenseAmount = calculateJustinExpenseTotal(amountsFromJustinAPI);
        amountToCarryOver = 225 + justinExpenseAmount;
    }

    async function fetchTotalAddedJustinSpending() {
        let justinEntertainmentApiData = await API.graphql({query: listJustinEntertainmentExpenses});
        let amountsFromJustinEntertainmentAPI = justinEntertainmentApiData.data.listJustinEntertainmentExpenses.items;
        let apiJustinEmergencyData = await API.graphql({query: listJustinEmergencyExpenses});
        let amountsFromJustinEmergencyAPI = apiJustinEmergencyData.data.listJustinEmergencyExpenses.items;
        let justinExtraApiData = await API.graphql({query: listJustinExtrasExpenses});
        let amountsFromJustinExtrasAPI = justinExtraApiData.data.listJustinExtrasExpenses.items;
        let justinGasApiData = await API.graphql({query: listJustinGasExpenses});
        let amountsFromJustinGasAPI = justinGasApiData.data.listJustinGasExpenses.items;
        let justinGroceryApiData = await API.graphql({query: listJustinGroceryExpenses});
        let amountsFromJustinGroceryAPI = justinGroceryApiData.data.listJustinGroceryExpenses.items;
        let justinIanAllowanceApiData = await API.graphql({query: listJustinIanExpenses});
        let amountsFromJustinIanAllowanceAPI = justinIanAllowanceApiData.data.listJustinIanExpenses.items;
        let apiJustinTravelData = await API.graphql({query: listJustinTravelExpenses});
        let amountsFromJustinTravelAPI = apiJustinTravelData.data.listJustinTravelExpenses.items;
        let justinApiData = await API.graphql({query: listJustinExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinExpenses.items;
        setJustinExpenses(amountsFromJustinAPI);
        setJustinTravelExpenses(amountsFromJustinTravelAPI);
        setIanAllowanceJustinExpenses(amountsFromJustinIanAllowanceAPI);
        setGroceryJustinExpenses(amountsFromJustinGroceryAPI);
        setGasJustinExpenses(amountsFromJustinGasAPI);
        setExtrasJustinExpenses(amountsFromJustinExtrasAPI);
        setJustinEmergencyExpenses(amountsFromJustinEmergencyAPI);
        setEntertainmentJustinExpenses(amountsFromJustinEntertainmentAPI);
        justinExpenseAmount = calculateJustinExpenseTotal(amountsFromJustinAPI);
        justinTravelAmount = calculateJustinTravelExpenseTotal(amountsFromJustinTravelAPI);
        justinIanAllowanceAmount = calculateJustinIanAllowanceTotal(amountsFromJustinIanAllowanceAPI);
        justinGroceryAmount = calculateJustinGroceryTotal(amountsFromJustinGroceryAPI);
        justinGasAmount = calculateJustinGasTotal(amountsFromJustinGasAPI);
        justinExtrasAmount = calculateJustinExtrasTotal(amountsFromJustinExtrasAPI);
        totalJustinEmergencyAmount = calculateJustinEmergencyExpenseTotal(amountsFromJustinEmergencyAPI);
        justinEntertainmentAmount = calculateJustinEntertainmentTotal(amountsFromJustinEntertainmentAPI);

        totalJustinSpent = justinEntertainmentAmount + justinExpenseAmount + justinTravelAmount + justinIanAllowanceAmount + justinGroceryAmount + justinGasAmount + totalJustinEmergencyAmount + justinExtrasAmount;
    }

    async function fetchTotalAmountofBriItems() {
        const apiBriGroceryData = await API.graphql({query: listBriGroceryExpenses});
        const amountsFromBriGroceryAPI = apiBriGroceryData.data.listBriGroceryExpenses.items;
        const apiBriEntertainmentData = await API.graphql({query: listBriEntertainmentExpenses});
        const amountsFromBriEntertainmentAPI = apiBriEntertainmentData.data.listBriEntertainmentExpenses.items;
        const apiBriGasData = await API.graphql({query: listBriGasExpenses});
        const amountsFromBriGasAPI = apiBriGasData.data.listBriGasExpenses.items;
        const apiBriExtrasData = await API.graphql({query: listBriExtrasExpenses});
        const amountsFromBriExtrasAPI = apiBriExtrasData.data.listBriExtrasExpenses.items;
        const apiBriIanAllowanceData = await API.graphql({query: listBriIanExpenses});
        const amountsFromBriIanAllowanceAPI = apiBriIanAllowanceData.data.listBriIanExpenses.items;
        const apiBriTravelData = await API.graphql({query: listBriTravelExpenses});
        const amountsFromBriTravelAPI = apiBriTravelData.data.listBriTravelExpenses.items;
        const apiBriEmergencyData = await API.graphql({query: listBriEmergencyExpenses});
        const amountsFromBriEmergencyAPI = apiBriEmergencyData.data.listBriEmergencyExpenses.items;
        setBriEmergencyExpenses(amountsFromBriEmergencyAPI);
        setBriTravelExpenses(amountsFromBriTravelAPI);
        setGroceryBriExpenses(amountsFromBriGroceryAPI);
        setEntertainmentBriExpenses(amountsFromBriEntertainmentAPI);
        setGasBriExpenses(amountsFromBriGasAPI);
        setExtrasBriExpenses(amountsFromBriExtrasAPI);
        setIanAllowanceBriExpenses(amountsFromBriIanAllowanceAPI);
        totalBriTravelAmount = calculateBriTravelExpenseTotal(amountsFromBriTravelAPI);
        briGroceryAmount = calculateBriGroceryTotal(amountsFromBriGroceryAPI);
        briEntertainmentAmount = calculateBriEntertainmentTotal(amountsFromBriEntertainmentAPI);
        briGasAmount = calculateBriGasTotal(amountsFromBriGasAPI);
        briExtrasAmount = calculateBriExtrasTotal(amountsFromBriExtrasAPI);
        briIanAllowanceAmount = calculateBriIanAllowanceTotal(amountsFromBriIanAllowanceAPI);
        totalBriEmergencyAmount = calculateBriEmergencyExpenseTotal(amountsFromBriEmergencyAPI);

    }

    async function createNewJustinExpense(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            JustinExpenseName: form.get("JustinExpenseName"),
            JustinExpenseAmount: form.get("JustinExpenseAmount"),

        };
        await API.graphql({
            query: createJustinExpense,
            variables: {input: data},
        });
        await fetchTotalAmountofJustinExpense()
        event.target.reset();
    }

    async function deleteOldJustinExpense({id}) {
        const newExpense = JustinExpense.filter((expense) => expense.id !== id);
        setJustinExpenses(newExpense);
        await API.graphql({
            query: deleteJustinExpense,
            variables: {input: {id}},
        });
        await fetchTotalAmountofJustinExpense()
    }

    let amountToSendToBri = 125 - (briGroceryAmount + briEntertainmentAmount + briGasAmount + briIanAllowanceAmount + totalBriTravelAmount + totalBriEmergencyAmount);

    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Justin" value="1"/>
                    </TabList>
                </Box>
                <TabPanel sx={{padding: 0, marginTop: 3}} value="1">
                    <Typography textAlign="center">
                        Total amount Justin has spent <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}>{currencyFormat(-justinExpenseAmount)}</Typography>
                    </Typography>
                    <View as="form" margin="1rem 1rem 1rem 1rem" onSubmit={createNewJustinExpense}>
                        <Flex direction="row" justifyContent="center">
                            <TextField
                                name="JustinExpenseName"
                                placeholder="Name"
                                label="Name"
                                variation="quiet"
                                required
                            />
                            <TextField
                                name="JustinExpenseAmount"
                                placeholder="Amount"
                                label="Amount"
                                variation="quiet"
                                required
                            />
                            <Button type="submit" variant="contained">
                                Add
                            </Button>
                        </Flex>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 350}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Expense</TableCell>
                                        <TableCell align="left">Created</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="left">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {JustinExpense.map((row) => (

                                        <TableRow
                                            key={row.id || row.JustinExpenseAmount || row.JustinExpenseName}
                                        >
                                            <TableCell align="left">{row.JustinExpenseName}</TableCell>
                                            <TableCell align="left">{convertDate(row.createdAt)}</TableCell>
                                            <TableCell align="center">{currencyFormat(row.JustinExpenseAmount)}</TableCell>
                                            <Button style={{marginTop: 9}} variant="contained"
                                                    onClick={() => deleteOldJustinExpense(row)}>
                                                Delete
                                            </Button>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </View>
                </TabPanel>
                <div><br></br></div>
                <Typography textAlign="center">Amount to carry over at the end<br/> of the month is
                    <Typography sx={{
                        fontWeight: 'bold',
                        display: 'inline'
                    }}> {currencyFormat(amountToCarryOver)}</Typography>
                </Typography>
                <div><br></br></div>
            </TabContext>
            <Typography textAlign="center">
                Amount to send to Bri <Typography sx={{
                fontWeight: 'bold',
                display: 'inline'
            }}>{currencyFormat(amountToSendToBri)}</Typography>
            </Typography>
            <div><br></br></div>
            <Typography textAlign="center">
                Amount spent by Justin <Typography sx={{
                fontWeight: 'bold',
                display: 'inline'
            }}>{currencyFormat(-totalJustinSpent)}</Typography>
            </Typography>
        </Box>
    );
}