import React from "react";
import "@aws-amplify/ui-react/styles.css";
import EntertainmentDialog from "../components/Entertainment/EntertainmentDialog";
import GroceriesDialog from "../components/Groceries/GroceriesDialog";
import GasDialog from "../components/Gas/GasDialog";
import ExtrasDialog from "../components/Extras/ExtrasDialog";
import IanAllowanceDialog from "../components/IanAllowance/IanAllowanceDialog";
import HomePieDialog from "../components/HomePie/HomePieDialog";
import EmergencySavingsDialog from "../components/EmergencySavings/EmergencySavingsDialog";
import BillsDialog from "../components/Bills/BillsDialog";
import TravelDialog from "../components/Travel/TravelDialog";

const Home = () => {
    return (
        <div>
            <HomePieDialog/>
            <GroceriesDialog/>
            <EntertainmentDialog/>
            <GasDialog/>
            <IanAllowanceDialog/>
            <BillsDialog/>
            <ExtrasDialog/>
            <TravelDialog/>
            <EmergencySavingsDialog/>
        </div>
    );
};
export default Home;