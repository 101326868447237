import React, {useEffect, useState} from "react";
import "@aws-amplify/ui-react/styles.css";
import Pie from "../JustinPie/JustinPie";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {API} from "aws-amplify";
import {listJustinExpenses} from "../../graphql/queries";
import {calculateJustinExpenseTotal, currencyFormat} from "../calculationFunctions";

let justinExpenseAmount = 0;

const JustinPieDialog = () => {
    const [justinExpenseExpenses, setJustinExpenses] = useState([]);

    useEffect(() => {
        fetchTotalAmountofJustinExpense()
    }, []);

    async function fetchTotalAmountofJustinExpense() {
        let justinApiData = await API.graphql({query: listJustinExpenses});
        let amountsFromJustinAPI = justinApiData.data.listJustinExpenses.items;
        setJustinExpenses(amountsFromJustinAPI);
        justinExpenseAmount = calculateJustinExpenseTotal(amountsFromJustinAPI);
    }

    return (
        <div>
            <Typography
                minHeight="10vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{color: 'white', fontSize: "xx-large"}}>Running dollars spent
                {" " + currencyFormat(-justinExpenseAmount)}</Typography>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="5vh"
            >
                <Pie mb={2} percentage={justinExpenseAmount} colour={"#0DA6C2"}/>
            </Box>
        </div>
    );
};
export default JustinPieDialog;