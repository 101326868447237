import React, {useState} from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import {withAuthenticator,} from "@aws-amplify/ui-react";
import Home from "./pages/Home";
import Justin from "./pages/Justin";
import Bri from "./pages/Bri";
import {BottomNavigation, BottomNavigationAction, Paper} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import Face2Icon from "@mui/icons-material/Face2";
import FaceIcon from "@mui/icons-material/Face";

const App = () => {
    const [value, setValue] = React.useState(0);
    const [active, setActive] = useState("Home")
    const navStyle = {backgroundColor: '#262450'}
    const homeStyle = {backgroundColor: '#19173D', height: 1350}


    return (
        <div style={homeStyle}>
            <Paper>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    style={navStyle}
                    sx={{"& .MuiBottomNavigationAction-root": {color: '#7b78AA'}}}
                >
                    <BottomNavigationAction type="button" onClick={() => setActive("Home")} label="Home"
                                            icon={<HomeIcon />}/>
                    <BottomNavigationAction type="button" onClick={() => setActive("Bri")} label="Bri"
                                            icon={<Face2Icon />}/>
                    <BottomNavigationAction type="button" onClick={() => setActive("Justin")} label="Justin"
                                            icon={<FaceIcon />}/>
                </BottomNavigation>
            </Paper>

            <div>
                {active === "Home" && <Home/>}
                {active === "Justin" && <Justin/>}
                {active === "Bri" && <Bri/>}
            </div>
        </div>
    );
};

export default withAuthenticator(App);